define('due-dashboard/controllers/companies/libraries/surveys/new', ['exports', 'ember', 'due-dashboard/helpers/default-survey-v2-style', 'due-dashboard/helpers/default-survey-v2-template', 'due-dashboard/helpers/default-survey-v2-translations', 'due-dashboard/utils/object-promise-proxy'], function (exports, _ember, _dueDashboardHelpersDefaultSurveyV2Style, _dueDashboardHelpersDefaultSurveyV2Template, _dueDashboardHelpersDefaultSurveyV2Translations, _dueDashboardUtilsObjectPromiseProxy) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var copy = _ember['default'].copy;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Controller.extend({
    loadingPromise: null,
    refreshPromise: null,
    isLoading: true,
    surveyNameError: false,
    livePublishAlertIsOpen: false,
    draftPublishedAlertIsOpen: false,
    setupAlertIsOpen: false,
    editAlertIsOpen: false,
    currentGroupName: 'content',
    selectedDesignElement: { component: "logo-design", icon: "panorama_image", name: "logo" },
    currentLanguage: 'all',
    languagesToDisplay: [],
    setupDone: false,
    newSurveyName: '',
    defaultTags: ['EN', 'FR', 'NL', 'ES', 'ZH', 'ZHT', 'KO', 'JA', 'TH'],
    reloadPreviewUrl: false,
    unsavedChanges: false,
    unsavedChangesAlertIsOpenBrowser: false,
    unsavedChangesMap: [],

    survey_draft: null,
    FALLBACK_FONTS: [{ name: 'Arial, sans-serif', value: 'Arial, sans-serif' }, { name: 'Inter, sans-serif', value: 'Inter, sans-serif' }, { name: 'Time New Roman, serif', value: 'Time New Roman, serif' }],
    dropdownButtonActions: computed('survey', 'survey.status', function () {
      var survey = this.get('survey');

      if (!survey) {
        return [];
      }

      return survey.get('status') === 'live' ? [{ color: ' ', icon: '', action: 'distributeSurvey', text: this.get('i18n').t('words.distribute') }, { color: ' ', icon: '', action: 'editSurveyName', text: this.get('i18n').t('words.settings') }, { type: 'separator' }, { color: ' ', icon: '', action: 'archiveSurvey', text: this.get('i18n').t('words.archive') }, { color: ' ', icon: '', action: 'openDeleteSurveyAlert', text: this.get('i18n').t('words.delete_maj') }] : [{ color: ' ', icon: '', action: 'onPublishSurvey', text: this.get('i18n').t('words.publish') }, { color: ' ', icon: '', action: 'editSurveyName', text: this.get('i18n').t('words.settings') }, { type: 'separator' }, { color: ' ', icon: '', action: 'archiveSurvey', text: this.get('i18n').t('words.archive') }, { color: ' ', icon: '', action: 'openDeleteSurveyAlert', text: this.get('i18n').t('words.delete_maj') }];
    }),

    thankYouPage: computed('computedSteps', function () {
      var steps = this.get('computedSteps');
      if (!steps || steps.length === 0) return null;
      return steps[steps.length - 1];
    }),

    currentPreviewLanguage: computed('survey_draft.main_language', function () {
      return this.get('survey_draft.main_language.tag') || this.get('currentAccount.content.language.tag');
    }),

    availableMainLanguages: computed('currentAccount', function () {
      var availableLanguages = this.get('currentAccount.dashboardLanguages') || [];
      return availableLanguages;
    }),

    steps: [],
    computedSteps: computed('steps', 'unsavedChangesMap', function () {
      var steps = copy(this.get('steps'), true);
      var unsavedChangesMap = this.get('unsavedChangesMap');

      var computedSteps = steps.map(function (step, stepIdx) {
        step.uuid = step.uuid || crypto.randomUUID();
        (step.conditions || []).forEach(function (stepCondition) {
          if (stepCondition) {
            stepCondition.uuid = stepCondition.uuid || crypto.randomUUID();
            stepCondition.has_been_cmptd = false;
            if (stepCondition.data.answer_in) {
              stepCondition.step_uuid = steps[stepCondition.data.answer_step].uuid;
              stepCondition.element_uuid = steps[stepCondition.data.answer_step].survey_elements_attributes[stepCondition.data.answer_element].uuid;
            }
          }
        });
        step.survey_elements_attributes.forEach(function (elem, elemIdx) {
          elem.uuid = elem.uuid || crypto.randomUUID();
          elem.has_unsaved_changes = unsavedChangesMap.filter(function (unsavedElement) {
            return unsavedElement.step === stepIdx && unsavedElement.element === elemIdx;
          }).length > 0;
          if (elem.kind === 'group') {
            elem.content.children = [];
            elem.content.child_indexes_info = (elem.content.child_indexes || []).map(function (idx) {
              return step.survey_elements_attributes[idx].uuid;
            });
            (elem.content.child_indexes || []).forEach(function (idx) {
              step.survey_elements_attributes[idx].grouped = true;
              elem.content.children.push(step.survey_elements_attributes[idx]);
            });
          }
          if (elem.conditions) {
            elem.conditions.forEach(function (elemCondition) {
              if (elemCondition) {
                elemCondition.uuid = elemCondition.uuid || crypto.randomUUID();
                elemCondition.has_been_cmptd = false;
                if (elemCondition.data.answer_in) {
                  elemCondition.step_uuid = steps[elemCondition.data.answer_step].uuid;
                  elemCondition.element_uuid = steps[elemCondition.data.answer_step].survey_elements_attributes[elemCondition.data.answer_element].uuid;
                }
              }
            });
          }
        });
        step.survey_elements_attributes = step.survey_elements_attributes.filter(function (n) {
          return !n.toDelete;
        }); // remove empty entries;
        return step;
      });
      this._transformTranslations(computedSteps);
      return computedSteps;
    }),

    _transformTranslations: function _transformTranslations(steps) {
      var _translations = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var translations = _translations || this.get('survey_draft.translations');
      var cmpTranslations = {};

      if (translations && translations['_languages']) {
        var tags = translations['_languages'];
        tags.forEach(function (tag) {
          tag = tag.toLowerCase();
          if (translations[tag]) {
            cmpTranslations[tag] = { elements: {} };
            var steps_i = Object.keys(translations[tag].elements || {}).map(function (i) {
              return parseInt(i);
            });
            steps_i.forEach(function (step_idx) {
              var step_uuid = steps[step_idx].uuid;
              if (translations[tag] && translations[tag].elements && translations[tag].elements[step_idx]) {
                var elems_i = Object.keys(translations[tag].elements[step_idx]).map(function (i) {
                  return parseInt(i);
                });
                elems_i.forEach(function (elem_idx) {
                  if (steps[step_idx].survey_elements_attributes[elem_idx] == undefined) {
                    return;
                  }
                  var elem_uuid = steps[step_idx].survey_elements_attributes[elem_idx].uuid;
                  if (!cmpTranslations[tag].elements[step_uuid]) {
                    cmpTranslations[tag].elements[step_uuid] = {};
                  }
                  cmpTranslations[tag].elements[step_uuid][elem_uuid] = copy(translations[tag].elements[step_idx][elem_idx], true) || {};
                });
              }
            });
          }
        });
        cmpTranslations._languages = translations._languages;
      }
      this.set('computedTranslations', cmpTranslations);
    },

    navbarGroups: computed(function () {
      return [this._getContentConfiguration(), this._getDesignConfiguration(), this._getTranslationsConfiguration()];
    }),

    selectedGroup: computed('currentGroupName', 'computedSteps', 'segmentsList', 'questionsList', 'ratingScalesList', 'isLoading', 'survey_draft', 'steps', 'confirmMainLanguageAlertIsOpenFromPublish', 'thankYouPage', 'loadingPromise', function () {
      return this._getCurrentConfiguration();
    }),

    isTranslationsGroup: computed('currentGroupName', function () {
      if (this.get('currentGroupName') != 'translations') {
        return false;
      }
      return true;
    }),

    publishDisabled: computed('survey.name', 'loadingPromise', function () {
      var surveyName = this.get('survey.name');
      var surveyStatus = this.get('survey.status');
      var loadingPromise = this.get('loadingPromise');

      return surveyName.length > 0 || surveyStatus !== 'live' || loadingPromise.isPending;
    }),

    defaultSurveyLanguage: computed('currentAccount.content.language', function () {
      return this.get('currentAccount.content.language');
    }),

    _queryCustomFonts: function _queryCustomFonts() {
      var _this = this;

      this.store.query('font', { sort: '-created_at' }).then(function (fonts) {
        var fallbackFonts = _this.get('FALLBACK_FONTS');
        fonts = _this._formatFonts(fonts);
        _this.set('customFonts', fallbackFonts.concat(fonts));
      });
    },

    _formatFonts: function _formatFonts(fonts) {
      return fonts.map(function (font) {
        return {
          name: font.get('name'),
          value: font.get('font')
        };
      });
    },

    _getContentConfiguration: function _getContentConfiguration() {
      return {
        name: 'content',
        title: 'words.content',
        icon: 'tio-layers',
        component: 'v2/libraries/surveys/navbar/-steps',
        items: this.get('computedSteps'),
        actionsCallback: 'dispatchAction',
        thankYouPage: this.get('thankYouPage'),
        autoSaveSurveyCallback: 'saveSurvey',
        autoSaveRevisionCallback: 'saveRevision',
        segmentsList: this.get('segmentsList'),
        questionsList: this.get('questionsList'),
        ratingScalesList: this.get('ratingScalesList'),
        isLoading: this.get('isLoading'),
        unsavedChanges: this.get('unsavedChanges'),
        updateUnsavedChangesMap: 'updateUnsavedChangesMap',
        updateContentAction: 'updateDraftContent',
        confirmMainLanguageAlertIsOpenFromPublish: this.get('confirmMainLanguageAlertIsOpenFromPublish'),
        loadingPromise: this.get('loadingPromise')
      };
    },

    _getDesignConfiguration: function _getDesignConfiguration() {
      return {
        name: 'design',
        title: 'words.design',
        icon: 'tio-paint_bucket',
        component: 'v2/libraries/surveys/navbar/-design',
        actionsCallback: 'dispatchAction',
        autoSaveRevisionCallback: 'saveRevision',
        survey_draft: this.get('survey_draft'),
        isLoading: this.get('isLoading'),
        customFonts: this.get('customFonts'),
        confirmMainLanguageAlertIsOpenFromPublish: this.get('confirmMainLanguageAlertIsOpenFromPublish')
      };
    },

    _getTranslationsConfiguration: function _getTranslationsConfiguration() {
      return {
        name: 'translations',
        title: 'words.translations',
        icon: 'tio-globe',
        component: 'v2/libraries/surveys/navbar/-translations',
        actionsCallback: 'dispatchAction',
        autoSaveRevisionCallback: 'saveRevision',
        selectTranslationCallback: 'selectTranslation',
        survey_draft: this.get('survey_draft'),
        isLoading: this.get('isLoading'),
        confirmMainLanguageAlertIsOpenFromPublish: this.get('confirmMainLanguageAlertIsOpenFromPublish'),
        resetConfirmMainLnaguageAlert: 'resetConfirmMainLnaguageAlert'
      };
    },

    _getCurrentConfiguration: function _getCurrentConfiguration() {
      var currentGroupName = this.get('currentGroupName');

      return ({
        content: this._getContentConfiguration(),
        design: this._getDesignConfiguration(),
        translations: this._getTranslationsConfiguration()
      })[currentGroupName];
    },

    _updateRevision: function _updateRevision(publish, steps) {
      var _this2 = this;

      var draft = this.get('survey_draft');
      var editedSteps = steps || this.get('selectedGroup.items') || draft.get('survey_content.survey_steps_attributes');
      var survey_content = {
        "survey_steps_attributes": this._computeSteps(editedSteps)
      };
      var translations = this.get('currentGroupName') === 'translations' ? draft.get('translations') : this._computeTranslations(editedSteps);
      set(draft, 'name', this.get('survey.name'));
      set(draft, 'survey_content', survey_content);
      set(draft, 'translations', translations);
      this.set('steps', survey_content.survey_steps_attributes);

      var promise = draft.save();
      this.set('loadingPromise', promise);

      promise.then(function (draft) {
        _this2.set('unsavedChanges', false);
        _this2.set('survey_draft', draft);
        _this2.set('unsavedChangesMap', []);
        _this2.notifyPropertyChange('survey_draft');
        if (publish) {
          _this2._getSurvey();
          _this2.set('livePublishAlertIsOpen', false);
        }
      })['catch'](function () {});
    },

    _getSurvey: function _getSurvey() {
      var _this3 = this;

      var surveyId = this.get('surveyId');
      var promise = this.get('store').findRecord('survey', surveyId, { reload: true });

      promise.then(function (survey) {
        _this3.set('survey', survey);
        _this3.send('publishSurvey', true);
      });
    },

    _updateSurvey: function _updateSurvey() {
      var published = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];
      var survey;
      return regeneratorRuntime.async(function _updateSurvey$(context$1$0) {
        var _this4 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            survey = this.get('survey');

            this.set('updateSurveyLoading', true);

            return context$1$0.abrupt('return', new Promise(function callee$1$0(resolve, reject) {
              var updatedSurvey;
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    context$2$0.next = 2;
                    return regeneratorRuntime.awrap(survey.save());

                  case 2:
                    updatedSurvey = context$2$0.sent;

                    if (updatedSurvey) {
                      this.set('survey', updatedSurvey);
                      this.set('draftPublishedAlertIsOpen', published);
                      this.set('surveyNameError', false);
                      this.set('updateSurveyLoading', false);
                      resolve(updatedSurvey);
                    } else {
                      if (this.get('editAlertIsOpen') || this.get('setupAlertIsOpen')) {
                        this.set('surveyNameError', true);
                      }
                      reject();
                    }

                  case 4:
                  case 'end':
                    return context$2$0.stop();
                }
              }, null, _this4);
            }));

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    _computeSubElements: function _computeSubElements(element, res) {
      var _this5 = this;

      element.content.children.forEach(function (subElement) {
        if (subElement.kind === 'group') {
          _this5._computeSubElements(subElement, res);
        }
        if (res.findIndex(function (e) {
          return e.uuid === subElement.uuid;
        }) === -1) res.push(subElement);
      });
    },

    _computeSteps: function _computeSteps(steps) {
      var _this6 = this;

      return steps.map(function (step) {
        var res = [];

        step.survey_elements_attributes.forEach(function (element) {
          if (element.kind === 'group') {
            _this6._computeSubElements(element, res);
          }
          if (res.findIndex(function (e) {
            return e.uuid === element.uuid;
          }) === -1) res.push(element);
        });

        res.forEach(function (element) {
          if (element.kind === 'group') {
            element.content.child_indexes = element.content.children.map(function (child) {
              return res.findIndex(function (elem) {
                return elem.uuid === child.uuid;
              });
            });
          }
        });

        res.forEach(function (element) {
          delete element.grouped;delete element.icon;delete element.dropdownOpts;delete element.has_unsaved_changes;
        });

        return {
          name: step.name,
          conditions: step.conditions,
          kind: step.kind,
          uuid: step.uuid,
          survey_elements_attributes: res
        };
      });
    },

    _computeTranslations: function _computeTranslations(steps) {
      var translations = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (translations) {
        this._transformTranslations(steps, translations);
        translations = this.get('computedTranslations');
      }
      var originalTranslations = translations || this.get('computedTranslations');
      var tags = this.get('survey_draft.translations._languages') || [];
      var res = {};

      tags.forEach(function (tag) {
        tag = tag.toLowerCase();
        res[tag] = { elements: {} };

        steps.forEach(function (step, stepIdx) {

          if (originalTranslations[tag] && originalTranslations[tag].elements) {
            res[tag].elements[stepIdx] = {};
            if (step.survey_elements_attributes && originalTranslations[tag].elements && !!originalTranslations[tag].elements[step.uuid]) {
              step.survey_elements_attributes.forEach(function (element, elemIdx) {
                res[tag].elements[stepIdx][elemIdx] = originalTranslations[tag].elements[step.uuid][element.uuid] || {};
              });
            }
          }
        });
      });
      res._languages = tags;
      return res;
    },

    _querySegments: function _querySegments() {
      var _this7 = this;

      var promise = this.store.query('companies-segment', { page: { number: 0, size: 1000 } });

      promise.then(function (segments) {
        var list = segments.toArray().map(function (segment) {
          return { text: segment.get('name') };
        }).sort(function (a, b) {
          if (a.text > b.text) return 1;
          if (a.text < b.text) return -1;
          return 0;
        });
        _this7.set('segmentsList', list);
      });
    },

    _queryRatingScales: function _queryRatingScales() {
      var _this8 = this;

      var filters = { filter: { kind: 'number,image', status: 'live' }, include: 'ratings,respondent_profiles', sort: '-created_at' };
      var promise = this.store.query('rating_scale', filters);

      this.set('ratingScalesPromise', promise);
      promise.then(function (rss) {
        _this8.set('ratingScalesList', rss.toArray());
      });
    },

    _resetPage: function _resetPage() {
      this.set('livePublishAlertIsOpen', false);
      this.set('draftPublishedAlertIsOpen', false);
      this.set('survey_draft', null);
      this.set('loadingPromise', null);
      this.set('survey', null);
      this.set('steps', []);
      this.set('currentGroup', this._getContentConfiguration());
      this.set('currentGroupName', 'content');
      this.set('previewSelectedStepIndex', 0);
      this.set('previewSelectedElementIndex', -1);
      this.set('surveyNameError', false);
      this.set('unsavedChanges', false);
      this.set('newSurveyName', '');
    },

    _draftCompletion: function _draftCompletion(draft) {
      this._normalizeClosedQuestions(draft);
      this.set('survey_draft', draft);
      this.set('steps', draft.get('survey_content.survey_steps_attributes') || []);
      this.set('state', 'steps');
      this.set('previewSelectedStepIndex', 0);
      this.set('previewSelectedElementIndex', -1);
      this.set('isLoading', false);
      this.notifyPropertyChange('isLoading');
      this._refreshLanguagesToDisplay();
    },

    questionsList: computed('steps', function () {
      var steps = this.get('steps');

      return steps.map(function (step, stepIndex) {
        return step.survey_elements_attributes.map(function (element, elemIndex) {
          return {
            step: stepIndex,
            element: elemIndex,
            text: element.name,
            kind: element.kind,
            content: element.content
          };
        });
      }).flat(1);
    }),

    willDestroyElement: function willDestroyElement() {
      this._resetPage();
    },

    _checkUnsavedChangesBeforeTransition: function _checkUnsavedChangesBeforeTransition() {
      if (this.unsavedChanges) {
        this.set('unsavedChangesAlertIsOpenBrowser', true);
      }
      return !this.unsavedChanges;
    },

    _querySurveys: function _querySurveys() {
      var _this9 = this;

      var filters = { filter: { status: 'live,draft' }, page: { size: 50, number: 0 }, sort: '-is_favorite,-created_at' };

      this.set('surveysAreLoading', true);

      this.store.query('survey', filters).then(function (surveys) {
        _this9.set('surveys', surveys.toArray());
        _this9.set('surveysAreLoading', false);
      });
    },

    _getDefaultTemplate: function _getDefaultTemplate(translations) {
      var dashboardLanguageTag, ratingScalesPromise, content;
      return regeneratorRuntime.async(function _getDefaultTemplate$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            dashboardLanguageTag = this.get('currentAccount.content.language.tag');
            ratingScalesPromise = this.get('ratingScalesPromise');
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(Promise.resolve(ratingScalesPromise).then(function (rss) {
              var defaultScaleId = rss.toArray().filter(function (rs) {
                return rs.get('default_rating_scale');
              })[0].id;
              return _dueDashboardHelpersDefaultSurveyV2Template['default'].compute(defaultScaleId, translations, dashboardLanguageTag);
            }));

          case 4:
            content = context$1$0.sent;
            return context$1$0.abrupt('return', content);

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    _getDefaultTranslations: function _getDefaultTranslations() {
      var companyName = this.get('currentAccount.content.company.name');
      var translations = _dueDashboardHelpersDefaultSurveyV2Translations['default'].compute(companyName);
      var defaultTags = this.get('defaultTags');

      translations._languages = defaultTags;
      return translations;
    },

    // To remove when all closed questions will be migrated
    _normalizeClosedQuestions: function _normalizeClosedQuestions(draft) {
      var save = false;
      var survey_steps_attributes = draft.get('survey_content.survey_steps_attributes') || [];
      survey_steps_attributes.forEach(function (step) {
        step.survey_elements_attributes.forEach(function (elem) {
          if (elem.kind === 'single_select' || elem.kind === 'multiple_select') {
            (function () {
              var options = elem.content.options;
              var cmptdOpts = options && (save |= !Array.isArray(options)) ? Object.keys(options).map(function (optionKey) {
                return { key: optionKey, value: options[optionKey] };
              }) : options || [];
              elem.content.options = cmptdOpts;
            })();
          }
        });
      });
      if (save) draft.save();
    },

    _getDefaultHtmlMeta: function _getDefaultHtmlMeta() {
      var companyName = this.get('currentAccount.content.company.name');
      var defaultTags = this.get('defaultTags');
      var meta = {};

      defaultTags.forEach(function (tag) {
        meta[tag.toLowerCase()] = { title: companyName };
      });

      return meta;
    },

    _getSurveyRevision: function _getSurveyRevision(survey_id) {
      var _this10 = this;

      var draftPromise = this.get('store').findRecord('survey-draft', survey_id);
      this.set('loadingPromise', draftPromise);
      draftPromise.then(function (draft) {
        _this10._draftCompletion(draft);
      });
    },

    _refreshLanguagesToDisplay: function _refreshLanguagesToDisplay() {
      var _this11 = this;

      var mainLanguageTag = this.get('survey_draft.main_language.tag');
      var translations = this.get('survey_draft.translations') || {};
      if (mainLanguageTag != undefined) {
        (function () {
          var languagesToDisplay = [mainLanguageTag];
          var lang = _this11.get('currentLanguage');
          if (['all', mainLanguageTag].includes(lang)) {
            if (lang != 'all') {
              _this11.set('currentLanguage', 'all');
            }
            var languages = translations['_languages'] || [];
            languages.forEach(function (language) {
              if (!languagesToDisplay.includes(language)) {
                languagesToDisplay.push(language);
              }
            });
          } else {
            languagesToDisplay.push(lang);
          }
          _this11.set('languagesToDisplay', languagesToDisplay);
        })();
      } else {
        this.set('languagesToDisplay', []);
      }
    },

    actions: {
      initModel: function initModel(survey_id) {
        var _this12 = this;

        this.set('surveyId', survey_id);
        this._queryCustomFonts();
        this._querySegments();
        this._queryRatingScales();
        this._querySurveys();
        this.set('state', 'steps');
        this.set('previewSelectedStepIndex', 0);
        this.set('previewSelectedElementIndex', -1);
        this.set('previewStepIndex', 0);

        if (survey_id) {
          this.set('setupAlertIsOpen', false);
          this.set('editAlertIsOpen', false);
          this.set('isLoading', true);
          this.notifyPropertyChange('isLoading');
          var promise = this.get('store').findRecord('survey', survey_id);
          var draftPromise = this.get('store').findRecord('survey-draft', survey_id);
          this.set('loadingPromise', promise);
          promise.then(function (survey) {
            _this12.set('survey', survey);_this12.set('newSurveyName', survey.get('name'));
          });
          draftPromise.then(function (draft) {
            _this12._draftCompletion(draft);
          });
        } else {
          (function () {
            _this12.set('setupAlertIsOpen', true);
            var survey = _this12.get('store').createRecord('survey', {
              status: 'draft',
              company: _this12.get('currentAccount.content.company'),
              is_new_survey: true
            });
            var promise = survey.save();
            var userLanguage = _this12.get('currentAccount.content.language');
            _this12.set('newSurveyMainLanguage', userLanguage);

            _this12.set('loadingPromise', promise);
            promise.then(function (survey) {
              _this12.set('survey', survey);
              _this12.get('store').findRecord('survey-draft', survey.id).then(function callee$3$0(draft) {
                var translations;
                return regeneratorRuntime.async(function callee$3$0$(context$4$0) {
                  var _this13 = this;

                  while (1) switch (context$4$0.prev = context$4$0.next) {
                    case 0:
                      set(draft, 'design_options', _dueDashboardHelpersDefaultSurveyV2Style['default'].compute());
                      translations = this._getDefaultTranslations();
                      context$4$0.t0 = draft;
                      context$4$0.next = 5;
                      return regeneratorRuntime.awrap(this._getDefaultTemplate(translations));

                    case 5:
                      context$4$0.t1 = context$4$0.sent;
                      set(context$4$0.t0, 'survey_content', context$4$0.t1);

                      set(draft, 'main_language', userLanguage);
                      set(draft, 'html_meta', this._getDefaultHtmlMeta());
                      set(draft, 'confirm_main_language', true);
                      draft.save().then(function (updatedDraft) {
                        _this13._draftCompletion(updatedDraft);
                        _this13.toggleProperty('reloadPreviewUrl');
                        _this13.set('isLoading', false);
                      });

                    case 11:
                    case 'end':
                      return context$4$0.stop();
                  }
                }, null, _this12);
              });
            });
          })();
        }
      },

      saveSurvey: function saveSurvey() {
        this._updateSurvey();
      },

      saveRevision: function saveRevision(_steps) {
        this.send('closeUnsavedChangesAlert');
        var steps = _steps && _steps.length ? _steps : this.get('selectedGroup.items');
        debounce(this, this._updateRevision, false, steps, 600);
      },

      surveyNameChanged: function surveyNameChanged(name) {
        var survey = this.get('survey');
        set(survey, 'name', name);
        debounce(this, this._updateRevision, 600);
      },

      dispatchAction: function dispatchAction(action) {
        for (var _len = arguments.length, arg = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          arg[_key - 1] = arguments[_key];
        }

        this.send.apply(this, [action].concat(arg));
      },

      selectTranslation: function selectTranslation(languagesToDisplay, currentLanguage) {
        this.set('currentLanguage', currentLanguage);
        this.set('languagesToDisplay', languagesToDisplay);
      },

      refreshLanguagesToDisplay: function refreshLanguagesToDisplay() {
        this._refreshLanguagesToDisplay();
      },

      onPublishSurvey: function onPublishSurvey() {
        var survey = this.get('survey');

        if (survey.get('status') === 'live') {
          this.set('livePublishAlertIsOpen', true);
        } else {
          this.send('publishSurvey');
        }
      },

      publishSurvey: function publishSurvey(publish) {
        var draft = this.get('survey_draft');
        var survey = this.get('survey');

        if (publish) {
          set(survey, 'status', 'live');
          debounce(this, this._updateSurvey, publish, 600);
          this._getSurveyRevision(survey.get('id'));
        } else {
          draft.set('active', true);
          debounce(this, this._updateRevision, true, 600);
        }
      },

      closePublishedAlert: function closePublishedAlert() {
        this.set('draftPublishedAlertIsOpen', false);
      },

      discardSurvey: function discardSurvey() {
        var _this14 = this;

        this.set('isLoading', true);
        var promise = this.get('survey_draft').destroyRecord();
        this.set('discardPromise', promise);
        promise.then(function (oldDraft) {
          var draftPromise = _dueDashboardUtilsObjectPromiseProxy['default'].create({
            promise: new Promise(function callee$2$0(resolve, reject) {
              var draft, translations;
              return regeneratorRuntime.async(function callee$2$0$(context$3$0) {
                while (1) switch (context$3$0.prev = context$3$0.next) {
                  case 0:
                    context$3$0.next = 2;
                    return regeneratorRuntime.awrap(this.get('store').findRecord('survey-draft', oldDraft.id));

                  case 2:
                    draft = context$3$0.sent;

                    if (!(Object.keys(draft.get('design_options')).length == 0)) {
                      context$3$0.next = 15;
                      break;
                    }

                    draft.set('design_options', _dueDashboardHelpersDefaultSurveyV2Style['default'].compute());
                    draft.set('main_language', this.get('currentAccount.content.language'));

                    if (!(!draft.get('survey_content') || draft.get('survey_content').survey_steps_attributes.length === 0)) {
                      context$3$0.next = 13;
                      break;
                    }

                    translations = this._getDefaultTranslations();
                    context$3$0.t0 = draft;
                    context$3$0.next = 11;
                    return regeneratorRuntime.awrap(this._getDefaultTemplate(translations));

                  case 11:
                    context$3$0.t1 = context$3$0.sent;
                    context$3$0.t0.set.call(context$3$0.t0, 'survey_content', context$3$0.t1);

                  case 13:
                    context$3$0.next = 15;
                    return regeneratorRuntime.awrap(draft.save());

                  case 15:
                    resolve(draft);

                  case 16:
                  case 'end':
                    return context$3$0.stop();
                }
              }, null, _this14);
            })
          });
          _this14.set('loadingPromise', draftPromise);
          _this14.set('refreshPromise', draftPromise);
          draftPromise.then(function (draft) {
            _this14._draftCompletion(draft);
          });
        });
      },

      archiveSurvey: function archiveSurvey() {
        var survey = this.get('survey');

        set(survey, 'status', 'archived');
        debounce(this, this._updateRevision, 600);
        this.send('backToIndex');
      },

      deleteSurvey: function deleteSurvey() {
        var _this15 = this;

        var survey = this.get('survey');

        survey.set('status', 'deleted');
        survey.set('status', 'deleted');
        var promise = survey.save();
        this.set('deleteSurveyPromise', promise);
        promise.then(function () {
          _this15._querySurveys(false);
          _this15.set('surveyToDelete', null);
          _this15.set('deleteSurveyPromise', null);
          _this15.send('closeDeleteSurveyAlert');
          _this15.send('backToIndex');
        });
      },

      distributeSurvey: function distributeSurvey() {
        var surveyId = this.get('survey.id');
        this.transitionToRoute('companies.surveys.edit.publishing', surveyId);
        this._resetPage();
      },

      editSurveyName: function editSurveyName() {
        this.set('newSurveyName', this.get('survey.name'));
        this.set('newSurveyMainLanguage', this.get('survey_draft.main_language'));
        this.set('editAlertIsOpen', true);
      },

      backToIndex: function backToIndex() {
        this.transitionToRoute('companies.libraries.surveys.index');
        this._resetPage();
      },

      refreshSteps: function refreshSteps(steps) {
        this.send('saveRevision', steps);
      },

      closeAlert: function closeAlert(alert) {
        this.set(alert + 'AlertIsOpen', false);
      },

      updateCurrentGroup: function updateCurrentGroup(currentGroupName) {
        if (!this.get('isLoading')) {
          this.set('currentGroupName', currentGroupName);
        }
      },

      goToTranslations: function goToTranslations() {
        this.send('closeConfirmToPublishAlert');
        this.send('updateCurrentGroup', 'translations');
      },

      closeConfirmToPublishAlert: function closeConfirmToPublishAlert() {
        this.set('confirmMainLanguageBeforePublishAlertIsOpen', false);
      },

      resetConfirmMainLnaguageAlert: function resetConfirmMainLnaguageAlert() {
        this.set('confirmMainLanguageAlertIsOpenFromPublish', false);
      },

      validateSetup: function validateSetup() {
        var _this16 = this;

        var survey = this.get('survey');
        var draft_rev = this.get('survey_draft');
        var newName = this.get('newSurveyName');

        if (newName === '') newName = survey.get('name');
        set(survey, 'name', newName);
        set(draft_rev, 'main_language', this.get('newSurveyMainLanguage'));
        this._updateSurvey().then(function () {
          if (_this16.get('editAlertIsOpen')) {
            _this16.set('editAlertIsOpen', false);
          } else {
            _this16.transitionToRoute('companies.libraries.surveys.edit', _this16.get('survey.id'));
          }
        })['catch'](function () {});
        this._updateRevision();
      },

      goToSurvey: function goToSurvey(survey) {
        if (survey && this.get('surveyId') && survey.get('id') !== this.get('surveyId')) {
          this._resetPage();
          this.transitionToRoute('companies.libraries.surveys.edit', survey.get('id'));
        }
      },

      selectNewSurveyMainLanguage: function selectNewSurveyMainLanguage(language) {
        this.set('newSurveyMainLanguage', language);
      },

      closeEditionAlert: function closeEditionAlert() {
        this.set('editAlertIsOpen', false);
      },

      onSurveyNameInputChange: function onSurveyNameInputChange() {
        this.set('surveyNameError', false);
      },

      openDeleteSurveyAlert: function openDeleteSurveyAlert(survey) {
        this.set('surveyToDelete', survey);
        this.set('deleteSurveyAlertIsOpen', true);
      },

      closeDeleteSurveyAlert: function closeDeleteSurveyAlert() {
        this.set('deleteSurveyAlertIsOpen', false);
      },

      closeUnsavedChangesAlert: function closeUnsavedChangesAlert() {
        this.set('unsavedChangesAlertIsOpenBrowser', false);
      },

      leavePage: function leavePage() {
        this.set('unsavedChangesAlertIsOpenBrowser', false);
        this.set('unsavedChanges', false);
        this.transitionToRoute('companies.libraries.surveys.index');
      },

      updateUnsavedChangesMap: function updateUnsavedChangesMap(map) {
        this.set('unsavedChangesMap', map);
        this.notifyPropertyChange('unsavedChangesMap');
      },

      updateDraftContent: function updateDraftContent(steps) {
        this.set('steps', steps);
      }
    }
  });
});