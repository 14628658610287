define('due-dashboard/components/v2/switch-button', ['exports', 'ember'], function (exports, _ember) {
  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{switch-button}} Switch button with title and short desription
   *
   * @module
   * @argument {boolean} switch - property to toggle
   * @argument {boolean} [disabled] - If is set to true, triggering the button becomes impossible
   * @argument {string} [title] - Title of the component
   * @argument {string} [description] - Description of the component
   * @argument {string} [onSwitch] - action triggeredd by switch
  
   * @example <caption>Full usage example of {{switch-button}} component</caption>
   * Usage :
   * ```hbs
   * {{v2/switch-button
   *    switch='property to switch'
   *    disabled=false
   *    title="title text"
   *    description="Small description text"
   *    onSwitch=(action 'toggleExample')
   * }}
   * ```
   */

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var RSVP = _ember['default'].RSVP;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['switch-button-container']

  });
});