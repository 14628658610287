define('due-dashboard/models/summary', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    company: belongsTo('company'),
    account: belongsTo('account'),
    tag: belongsTo('tag'),

    summary_content_updated: attr({ readOnly: true }),
    filters: attr(),

    cancel_reason: attr('string', { readOnly: true }),
    tag_name: attr('string', { readOnly: true }),
    client_feeling: attr('string'),
    client_comment: attr('string')
  });
});