define('due-dashboard/components/loading-skeleton/due-gradient-bar-skeleton', ['exports', 'ember'], function (exports, _ember) {
  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-gradient-bar-skeleton}} Simple skeleton component with gradient colors
   *
   * @module
   * @argument {string} [size] - size of component
   * @argument {string} [width] - width of the component
   * @argument {string} [height] - height of the component
   * @argument {string} [style] - css style to customise the component
   * @argument {string} [color] - custom color
   * @argument {string} [radius] - radius
   * @argument {string} [opacity] - opacity
   * @argument {boolean} [gradient] - boolean gradient
   * @argument {string} [customClass] - classes to customise the component
   * @example <caption>Full usage example of {{due-numbers-skeleton}} component</caption>
   * Usage :
   * ```hbs
   * {{loading-skeleton/due-gradient-bar-skeleton
   *   customClass='my-custom-class'
   *   size='medium'
   *   width=100
   *   height=15
   *   color='my-custom-color'
   *   style='my-custom-style'
   * }}
   * ```
   */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-gradient-bar-skeleton'],
    classNameBindings: ['customClass', 'size'],
    attributeBindings: ['style'],

    heightFromSize: { small: 20, medium: 40, large: 60 },
    widthFromSize: { small: 46, medium: 88, large: 100 },
    rendered: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('gradientUID', 'linear-gradient-' + guidFor(this));
    },

    computedGradientUID: computed('gradient', 'color', function () {
      return this.get('gradient') ? 'url(#' + this.get('gradientUID') + ')' : this.get('color') || '#F6F8FB';
    }),

    recWidth: computed('width', 'size', function () {
      return this.get('width') || this.get('widthFromSize')[this.get('size')] || 46;
    }),

    recHeight: computed('height', 'size', function () {
      return this.get('height') || this.get('heightFromSize')[this.get('size')] || 20;
    }),

    fillOpacity: computed('opacity', function () {
      return this.get('opacity') || 1;
    })
  });
});