define('due-dashboard/routes/companies/libraries/rating-scales/edit', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    controllerName: 'companies.libraries.rating-scales.new',

    model: function model(params) {
      return this.store.findRecord('rating_scale', params.rating_scale_id, { include: 'ratings,respondent_profiles' });
    },

    afterModel: function afterModel() {
      var controller = this.controllerFor('companies.libraries.rating-scales.new');
      setTimeout(function () {
        controller.send('setEditionValues');
      }, 0);
    },

    actions: {
      willTransition: function willTransition() {
        this.controller._resetPageState();
        return true;
      }
    }
  });
});