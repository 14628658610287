define("due-dashboard/templates/companies/report", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 10
                  },
                  "end": {
                    "line": 34,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/companies/report.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "component", ["reports/due-report-table/due-report-table-dynamic"], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [24, 22], [24, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [25, 39], [25, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 21], [25, 53]]], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [26, 30], [26, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "model.report.filters", ["loc", [null, [27, 28], [27, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "report", ["subexpr", "@mut", [["get", "report", ["loc", [null, [28, 21], [28, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [29, 21], [29, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reloadAction", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [30, 27], [30, 50]]], 0, 0], "redirectAction", ["subexpr", "action", ["changeReportRoute"], [], ["loc", [null, [31, 29], [31, 57]]], 0, 0], "canView", ["subexpr", "@mut", [["get", "canView", ["loc", [null, [32, 22], [32, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [23, 12], [33, 14]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 10
                    },
                    "end": {
                      "line": 48,
                      "column": 10
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/report.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "component", ["reports/due-report-table/due-report-table"], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [36, 22], [36, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [37, 39], [37, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 21], [37, 53]]], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [38, 30], [38, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "model.report.filters", ["loc", [null, [39, 28], [39, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "report", ["subexpr", "@mut", [["get", "report", ["loc", [null, [40, 21], [40, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [41, 21], [41, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reloadAction", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [42, 27], [42, 50]]], 0, 0], "redirectAction", ["subexpr", "action", ["changeReportRoute"], [], ["loc", [null, [43, 29], [43, 57]]], 0, 0], "generateSummaryAction", ["subexpr", "action", ["generateSummary"], [], ["loc", [null, [44, 36], [44, 62]]], 0, 0], "canView", ["subexpr", "@mut", [["get", "canView", ["loc", [null, [45, 22], [45, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "canUseSummaries", ["subexpr", "@mut", [["get", "canUseSummaries", ["loc", [null, [46, 30], [46, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [35, 12], [47, 14]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 48,
                        "column": 10
                      },
                      "end": {
                        "line": 58,
                        "column": 10
                      }
                    },
                    "moduleName": "due-dashboard/templates/companies/report.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "component", [["subexpr", "concat", ["reports/-", ["get", "column.component.type", ["loc", [null, [49, 44], [49, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 24], [49, 66]]], 0, 0]], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [50, 22], [50, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [51, 39], [51, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 21], [51, 53]]], 0, 0], "summary", ["subexpr", "@mut", [["get", "summary", ["loc", [null, [52, 22], [52, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "canUseSummaries", ["subexpr", "@mut", [["get", "canUseSummaries", ["loc", [null, [53, 30], [53, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "SummaryError", ["subexpr", "@mut", [["get", "SummaryError", ["loc", [null, [54, 27], [54, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "displaySummary", ["subexpr", "@mut", [["get", "displaySummary", ["loc", [null, [55, 29], [55, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "summaryPromise", ["subexpr", "@mut", [["get", "summaryPromise", ["loc", [null, [56, 29], [56, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [49, 12], [57, 14]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 58,
                        "column": 10
                      },
                      "end": {
                        "line": 72,
                        "column": 10
                      }
                    },
                    "moduleName": "due-dashboard/templates/companies/report.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n          ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "component", [["subexpr", "concat", ["reports/-", ["get", "column.component.type", ["loc", [null, [59, 44], [59, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 24], [59, 66]]], 0, 0]], ["content", ["subexpr", "@mut", [["get", "column", ["loc", [null, [60, 22], [60, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "layout", ["subexpr", "concat", ["layout_", ["get", "column.layout", ["loc", [null, [61, 39], [61, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [61, 21], [61, 53]]], 0, 0], "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [62, 30], [62, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "reportFilters", ["subexpr", "@mut", [["get", "model.report.filters", ["loc", [null, [63, 28], [63, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "report", ["subexpr", "@mut", [["get", "model.report", ["loc", [null, [64, 21], [64, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reload", ["loc", [null, [65, 21], [65, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "reloadAction", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [66, 27], [66, 50]]], 0, 0], "redirectAction", ["subexpr", "action", ["changeReportRoute"], [], ["loc", [null, [67, 29], [67, 57]]], 0, 0], "canView", ["subexpr", "@mut", [["get", "canView", ["loc", [null, [68, 22], [68, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [69, 20], [69, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "pieSize", ["subexpr", "@mut", [["get", "column.component.config.pie_size", ["loc", [null, [70, 22], [70, 54]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [59, 12], [71, 14]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 48,
                      "column": 10
                    },
                    "end": {
                      "line": 72,
                      "column": 10
                    }
                  },
                  "moduleName": "due-dashboard/templates/companies/report.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "column.component.type", ["loc", [null, [48, 24], [48, 45]]], 0, 0, 0, 0], "summary"], [], ["loc", [null, [48, 20], [48, 56]]], 0, 0]], [], 0, 1, ["loc", [null, [48, 10], [72, 10]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 10
                  },
                  "end": {
                    "line": 72,
                    "column": 10
                  }
                },
                "moduleName": "due-dashboard/templates/companies/report.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", [["subexpr", "or", [["subexpr", "eq", [["get", "column.component.type", ["loc", [null, [34, 33], [34, 54]]], 0, 0, 0, 0], "array"], [], ["loc", [null, [34, 29], [34, 63]]], 0, 0], ["subexpr", "eq", [["get", "column.component.type", ["loc", [null, [34, 68], [34, 89]]], 0, 0, 0, 0], "tag-breakdown"], [], ["loc", [null, [34, 64], [34, 106]]], 0, 0]], [], ["loc", [null, [34, 25], [34, 107]]], 0, 0], ["subexpr", "and", [["get", "column.component.api_version", ["loc", [null, [34, 113], [34, 141]]], 0, 0, 0, 0], ["subexpr", "eq", [["get", "column.component.api_version", ["loc", [null, [34, 146], [34, 174]]], 0, 0, 0, 0], 2], [], ["loc", [null, [34, 142], [34, 177]]], 0, 0]], [], ["loc", [null, [34, 108], [34, 178]]], 0, 0]], [], ["loc", [null, [34, 20], [34, 179]]], 0, 0]], [], 0, 1, ["loc", [null, [34, 10], [72, 10]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 8
                },
                "end": {
                  "line": 73,
                  "column": 8
                }
              },
              "moduleName": "due-dashboard/templates/companies/report.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "column.component.view_type", ["loc", [null, [22, 20], [22, 46]]], 0, 0, 0, 0], "segment-breakdown"], [], ["loc", [null, [22, 16], [22, 67]]], 0, 0]], [], 0, 1, ["loc", [null, [22, 10], [72, 17]]]]],
            locals: ["column"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 4
              },
              "end": {
                "line": 75,
                "column": 4
              }
            },
            "moduleName": "due-dashboard/templates/companies/report.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "report-line-container");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "line", ["loc", [null, [21, 16], [21, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [21, 8], [73, 17]]]]],
          locals: ["line"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 77,
                "column": 2
              },
              "end": {
                "line": 79,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/companies/report.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "reports/popups/access-denied", [], ["isOpen", ["subexpr", "not", [["get", "canView", ["loc", [null, [78, 47], [78, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 42], [78, 55]]], 0, 0], "action", ["subexpr", "action", ["redirectToDashboard"], [], ["loc", [null, [78, 63], [78, 93]]], 0, 0]], ["loc", [null, [78, 4], [78, 95]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 80,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/companies/report.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "report-page-header");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "header-title");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "filters-container");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "user-role-access-container");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "icon-container");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5, "class", "tio-poi");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "text-container");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "group-name");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "report-container");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [3, 3]);
          var element3 = dom.childAt(element2, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[3] = dom.createAttrMorph(element3, 'class');
          morphs[4] = dom.createMorphAt(element3, 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[6] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "get-value", [["get", "model.report.title", ["loc", [null, [4, 43], [4, 61]]], 0, 0, 0, 0], ["get", "currentLanguage", ["loc", [null, [4, 62], [4, 77]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 31], [4, 79]]], 0, 0], ["inline", "dashboard-filters", [], ["filtersUpdated", ["subexpr", "action", ["toggleReload"], [], ["loc", [null, [6, 41], [6, 64]]], 0, 0]], ["loc", [null, [6, 6], [6, 66]]], 0, 0], ["content", "roleName", ["loc", [null, [12, 35], [12, 47]]], 0, 0, 0, 0], ["attribute", "class", ["concat", ["location-name ", ["subexpr", "if", [["subexpr", "gt", [["get", "currentAccount.content.geoscope_name.length", ["loc", [null, [13, 46], [13, 89]]], 0, 0, 0, 0], 50], [], ["loc", [null, [13, 42], [13, 93]]], 0, 0], "overflow-ellipsis"], [], ["loc", [null, [13, 37], [13, 115]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "geoscopeName", ["loc", [null, [13, 117], [13, 133]]], 0, 0, 0, 0], ["block", "each", [["get", "model.report.elements", ["loc", [null, [19, 12], [19, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 4], [75, 13]]]], ["block", "unless", [["get", "canView", ["loc", [null, [77, 12], [77, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [77, 2], [79, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 81,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/companies/report.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "model.isPending", ["loc", [null, [2, 10], [2, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [80, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});