define('due-dashboard/routes/companies/libraries/rating-scales/new', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    controllerName: 'companies.libraries.rating-scales.new',

    actions: {
      willTransition: function willTransition() {
        this.controller._resetPageState();
        this.controller.set('createdRecord', true);
        return true;
      }
    }
  });
});