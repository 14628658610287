define('due-dashboard/routes/companies/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    redirect: function redirect() {
      var _this = this;

      var setSidebarAndRedirect = function setSidebarAndRedirect(sidebar) {
        if (sidebar && sidebar.get('elements').length > 0) {
          var element = _this.set('customFirstElement', sidebar.get('elements').find(function (element) {
            return element.route;
          }));
          if (element) {
            return element.param ? _this.transitionTo(element.route, element.param) : _this.transitionTo(element.route);
          }
        }
        _this.transitionTo('companies.overview');
      };
      if (this.get('currentAccount.canAccessStandardReport')) {
        setSidebarAndRedirect(_ember['default'].Object.create(this.get('currentAccount.standardReportSidebar')));
      } else if (this.get('currentAccount.content.profile') != 'custom') {
        this.store.query('profile-sidebar', {
          filter: { profile: this.get('currentAccount.content.profile') }
        }).then(function (sidebars) {
          var sidebar = sidebars.get('firstObject');
          setSidebarAndRedirect(sidebar);
        });
      } else {
        var promise = new RSVP.Promise(function (resolve) {
          return resolve(_this.get('currentAccount.content.account_custom_profile_right.sidebar'));
        });
        promise.then(function (sidebar) {
          setSidebarAndRedirect(sidebar);
        });
      }
    }
  });
});