define('due-dashboard/controllers/companies/report', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Controller.extend({
    globalFilters: service('feedback-search/global'),

    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    canUseSummaries: computed.alias('currentAccount.canUseSummaries'),

    reload: false,

    canView: computed('model.id', function () {
      var hasReportCustomRight = this.get('currentAccount.hasReportCustomRight'),
          reportRightsList = this.get('currentAccount.reportRightsList');

      return !hasReportCustomRight || reportRightsList.includes(this.get('report.id'));
    }),

    geoscopeName: computed('currentAccount.content', function () {
      if (this.get('currentAccount.content.profile') === 'admin' && !this.get('currentAccount.content.geoscope_name.length')) {
        return 'Worldwide';
      }
      return this.get('currentAccount.content.geoscope_name.length') > 0 ? this.get('currentAccount.content.geoscope_name') : '-';
    }),

    roleName: computed('currentAccount.content.account_custom_profile_right', function () {
      if (this.get('currentAccount.content.account_custom_profile_right.content')) {
        return this.get('currentAccount.content.account_custom_profile_right.name');
      } else {
        var profile = this.get('currentAccount.content.profile');
        return profile.charAt(0).toUpperCase() + profile.slice(1);
      }
    }),

    report: computed.alias('model'),

    actions: {
      toggleReload: function toggleReload() {
        this.toggleProperty('reload');
        this.set('displaySummary', false); // Temp Summaries POC
      },

      changeReportRoute: function changeReportRoute(route, params) {
        if (params) {
          this.transitionToRoute(route, params);
        } else {
          this.transitionToRoute(route);
        }
      },

      redirectToDashboard: function redirectToDashboard() {
        var sidebar = this.get('currentAccount.content.account_custom_profile_right.sidebar'),
            element = sidebar.get('elements').find(function (e) {
          if (e.type != 'title') {
            return e;
          }
        }),
            route = element.route,
            param = element.param;

        this.transitionToRoute(route, param);
      },

      // Summaries POC
      generateSummary: function generateSummary(tag_id) {
        var _this = this;

        var summaryFilters = this.get('globalFilters.current').toAPIFormat();

        this.set('displaySummary', true);
        this.set('summaryPromise', this.get('store').createRecord('summary', {
          filters: summaryFilters,
          tag: this.get('store').peekRecord('tag', tag_id)
        }).save()).then(function (summary) {
          _this.set('summary', summary);
        })['catch'](function (error) {
          _this.set('SummaryError', error);
          setTimeout(function () {
            return _this.set('displaySummary', false);
          }, 5000);
        });
      }
    }
  });
});