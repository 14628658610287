define('due-dashboard/components/v2/-action-header', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['due-action-header'],
    loading: false,
    searchSurveyValue: '',
    goToSurvey: null,

    discardAlertIsOpen: false,

    actionHeaderComboOption: computed('dropdownButtonActions', function () {
      var options = this.get('dropdownButtonActions');

      return [{ color: ' ', icon: '', action: 'openDiscardAlert', text: this.get('i18n').t('words.discard_changes') }, { type: 'separator' }].concat(options);
    }),

    languages: computed('survey_draft', 'survey_draft.translations', 'currentLanguage', function () {
      var _this = this;

      var draft_rev = this.get('survey_draft');
      var mainLanguage = this.get('survey_draft.main_language');
      if (!mainLanguage) return [];
      var currentLanguage = this.get('currentLanguage');
      var baseLanguages = currentLanguage === mainLanguage.get('tag') ? [] : [{ name: this.get('i18n').t('languages.' + mainLanguage.get('tag')), tag: mainLanguage.get('tag') }];
      if (draft_rev && !draft_rev.isPending) {
        var languages = baseLanguages.concat(draft_rev.get('translations') && draft_rev.get('translations')['_languages'] ? draft_rev.get('translations')['_languages'].map(function (lang) {
          return { name: _this.get('i18n').t('languages.' + lang), tag: lang };
        }) : []);
        var duplicateIndex = languages.findIndex(function (lang) {
          return lang.tag === currentLanguage;
        });
        if (duplicateIndex > -1) languages.splice(duplicateIndex, 1);
        return languages;
      }
      return [];
    }),

    computedSurveys: computed('surveys', 'filteredSurveys', 'searchSurveyValue', function () {
      return this.get('searchSurveyValue').length > 0 ? this.get('filteredSurveys') : this.get('surveys');
    }),

    _saveSurvey: function _saveSurvey() {
      var autoSaveCallback = this.get('autoSaveCallback');

      if (autoSaveCallback) {
        autoSaveCallback();
      }
    },

    _filterSurveys: function _filterSurveys() {
      var value = this.get('searchSurveyValue').toLowerCase();
      var surveys = this.get('surveys');
      if (!surveys || !surveys.length) return [];
      var filteredSurveys = surveys.filter(function (survey) {
        return survey.get('name').toLowerCase().includes(value);
      });
      this.set('filteredSurveys', filteredSurveys);
    },

    actions: {
      triggerAction: function triggerAction(action) {
        var callback = this.get('dropdownButtonCallback');
        if (action === 'openDiscardAlert') this.send(action);else if (callback) {
          callback(action);
        }
      },

      onInputChange: function onInputChange(value) {
        if (value === -1) {
          this._saveSurvey();
        }
      },

      openDiscardAlert: function openDiscardAlert() {
        this.set('discardAlertIsOpen', true);
      },

      discard: function discard() {
        var fct = this.get('discardCallback');

        if (fct) {
          fct();
        }
        this.send('toggleModalIsOpen', 'discard');
      },

      toggleModalIsOpen: function toggleModalIsOpen(modal) {
        this.toggleProperty(modal + 'AlertIsOpen');
      },

      backButton: function backButton() {
        var func = this.get('backButtonAction');
        var unsavedChanges = this.get('unsavedChanges');

        if (func && !unsavedChanges) func();else if (unsavedChanges) this.set('unsavedChangesAlertIsOpen', true);
      },

      goBack: function goBack() {
        var backFunc = this.get('backButtonAction');
        var survey = this.get('goToSurvey');
        var goToFunc = this.get('onDropdownSelect');

        this.set('unsavedChangesAlertIsOpen', false);
        this.set('unsavedChanges', false);
        if (survey && goToFunc) {
          this.set('goToSurvey', null);
          goToFunc(survey);
        } else if (!survey && backFunc) backFunc();
      },

      onDropdownSelect: function onDropdownSelect(survey) {
        var func = this.get('onDropdownSelect');
        var unsavedChanges = this.get('unsavedChanges');
        if (func && survey && !unsavedChanges) func(survey);else if (unsavedChanges) {
          this.set('goToSurvey', survey);
          this.set('unsavedChangesAlertIsOpen', true);
        }
      },

      onLanguageChanged: function onLanguageChanged(language) {
        if (language) {
          this.set('currentLanguage', language.tag);
        }
      },

      onSearchSurvey: function onSearchSurvey(value) {
        this.set('searchSurveyValue', value || "");
        this._filterSurveys();
      },

      publishSurvey: function publishSurvey() {
        var callback = this.get('publishCallback');
        if (callback) callback();
      },

      saveRevisionFromAlert: function saveRevisionFromAlert() {
        var callback = this.get('saveRevisionCallback');
        if (callback) {
          this.set('unsavedChangesAlertIsOpen', false);
          callback();
        }
      },

      closeUnsavedChangesAlert: function closeUnsavedChangesAlert() {
        this.set('unsavedChangesAlertIsOpen', false);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/-action-header}} header component, display actions components
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */