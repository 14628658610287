define('due-dashboard/models/rating', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({

    rating_scale: belongsTo('RatingScale', { async: true }),
    respondent_profile: belongsTo('RespondentProfile', { async: true }),

    position: attr('number'),
    score: attr('number'),
    picture: attr('string'),
    profile: attr('string'),

    display_scale_value: attr('string'),
    font_attributes: attr('string'),
    style: attr()
  });
});