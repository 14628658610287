define('due-dashboard/components/v2/libraries/surveys/navbar/steps/steps-content', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content'],

    openedGroupElementIndex: -1,
    hoverItemIndex: -1,
    deleteAlertIsOpen: false,
    addElementModalIsOpen: false,
    stepIndexToDelete: -1,
    clicks: 0,
    selectedStepNameIndex: -1,

    colors: {
      blue: '#1FB5F4',
      darkBlue: '#1B3448',
      green: '#3BC9DB',
      grey: '#E1E8EE',
      purple: '#7950F2',
      white: '#FFFFFF'
    },

    openedStepIndex: computed('selectedStepIndex', function () {
      return this.get('selectedStepIndex');
    }),

    dropdownButtonActionsSteps: computed(function () {
      return [{ color: ' ', icon: 'tio-copy', action: 'duplicateStep', text: this.get('i18n').t('words.duplicate_maj') }, { color: ' ', icon: 'tio-delete', action: 'openDeleteStepAlert', text: this.get('i18n').t('words.delete_maj') }];
    }),

    mappingInfoElement: computed(function () {
      return {
        'rating': { icon: 'tio-star_outlined', backgroundColor: this.colors.green, iconColor: this.colors.white },
        'short_text_input': { icon: 'tio-comment_text_outlined', backgroundColor: this.colors.blue, iconColor: this.colors.white },
        'long_text_input': { icon: 'tio-text_left', backgroundColor: this.colors.blue, iconColor: this.colors.white },
        'single_select': { icon: 'tio-checkmark_circle_outlined', backgroundColor: this.colors.purple, iconColor: this.colors.white },
        'multiple_select': { icon: 'tio-checkmark_square_outlined', backgroundColor: this.colors.purple, iconColor: this.colors.white },
        'title': { icon: 'tio-text', backgroundColor: this.colors.darkBlue, iconColor: this.colors.white },
        'text': { icon: 'tio-small_caps', backgroundColor: this.colors.darkBlue, iconColor: this.colors.white },
        'prev_next_btns': { icon: 'tio-swap_horizontal', backgroundColor: this.colors.grey, iconColor: this.colors.darkBlue },
        'redirect_btn': { icon: 'tio-link', backgroundColor: this.colors.grey, iconColor: this.colors.darkBlue },
        'group': { icon: 'tio-format_points' },
        'assign_segment': { icon: 'tio-tune', backgroundColor: this.colors.grey, iconColor: this.colors.darkBlue },
        'submit_step': { icon: 'tio-fast_forward', backgroundColor: this.colors.grey, iconColor: this.colors.darkBlue },
        'soft_complete': { icon: 'tio-save_outlined', backgroundColor: this.colors.grey, iconColor: this.colors.darkBlue }
      };
    }),

    computedSteps: computed('items', 'group', 'items.@each.conditions', function () {
      var _this = this;

      var items = this.get('items');
      var mappingInfoElement = this.get('mappingInfoElement');

      if (!items) {
        return [];
      }

      var res = items.map(function (item, index) {
        return {
          uuid: item.uuid,
          name: item.name,
          kind: item.kind,
          icon: 'tio-document_text_outlined',
          conditions: item.conditions.filter(function (c) {
            return c;
          }), // to remove empty objects
          survey_elements_attributes: item['survey_elements_attributes'].map(function (elem) {
            var element = {
              uuid: elem.uuid || crypto.randomUUID(),
              name: elem.name,
              icon: {
                name: mappingInfoElement[elem.kind].icon,
                style: {
                  container: 'background-color: ' + mappingInfoElement[elem.kind].backgroundColor,
                  icon: 'color: ' + mappingInfoElement[elem.kind].iconColor
                }
              },
              kind: elem.kind,
              conditions: elem.conditions || [],
              content: elem.content,
              new_question_id: elem.new_question_id,
              dropdownOpts: [{ color: ' ', icon: 'tio-copy', action: 'duplicateElement', text: _this.get('i18n').t('words.duplicate_maj').string }, { color: ' ', icon: 'tio-subdirectory_left', text: _this.get('i18n').t('words.move_to').string, options: items.map(function (step, stepIdx) {
                  // exclude current page + hard complete + thx you page
                  if (index !== stepIdx && stepIdx !== items.length - 1 && step.kind === 'content') return { text: stepIdx + 1 + '. ' + step.name, action: 'moveElementTo', position: stepIdx };
                }).filter(function (c) {
                  return c;
                }) }, { color: ' ', icon: 'tio-delete', action: 'openDeleteElementAlert', text: _this.get('i18n').t('words.delete_maj').string }],
              has_unsaved_changes: elem.has_unsaved_changes
            };
            if (elem.new_question_id) element.new_question_id = elem.new_question_id;
            return element;
          })
        };
      });

      return res;
    }),

    thankYouPageIndex: computed('items', function () {
      return this.get('items').length - 1;
    }),

    computedThankYouPage: computed('group', 'group.thankYouPage', 'items', function () {
      var _this2 = this;

      var thankYouPage = this.get('group.thankYouPage');
      var mappingInfoElement = this.get('mappingInfoElement');

      if (!thankYouPage) {
        return null;
      }
      return {
        uuid: thankYouPage.uuid || crypto.randomUUID(),
        name: thankYouPage.name || 'Thank you',
        icon: 'tio-door',
        kind: thankYouPage.kind,
        conditions: thankYouPage.conditions.filter(function (c) {
          return c;
        }), // to remove empty objects
        survey_elements_attributes: thankYouPage['survey_elements_attributes'].map(function (elem) {
          return {
            uuid: elem.uuid || crypto.randomUUID(),
            name: elem.name,
            icon: {
              name: mappingInfoElement[elem.kind].icon,
              style: {
                container: 'background-color: ' + mappingInfoElement[elem.kind].backgroundColor,
                icon: 'color: ' + mappingInfoElement[elem.kind].iconColor
              }
            },
            kind: elem.kind,
            conditions: elem.conditions || [],
            content: elem.content,
            new_question_id: elem.new_question_id,
            dropdownOpts: [{ color: ' ', icon: 'tio-copy', action: 'duplicateElement', text: _this2.get('i18n').t('words.duplicate_maj').string }, { color: ' ', icon: 'tio-delete', action: 'openDeleteElementAlert', text: _this2.get('i18n').t('words.delete_maj').string }],
            has_unsaved_changes: elem.has_unsaved_changes
          };
        })
      };
    }),

    _stepNameChanged: function _stepNameChanged(index, value) {
      var steps = this.get('items');

      if (value && index !== undefined && steps[index].name !== value && value !== -1) {
        steps[index].name = value;
      }

      if (steps[index].name !== value && value === -1) {
        this.send('dispatchAction', 'refreshSteps');
      }
    },

    _changeSelectedStep: function _changeSelectedStep(stepIndex) {
      var stateToggleCallback = this.get('onStateChange');
      var steps = this.get('computedSteps');
      var selectedStep = steps[stepIndex];
      this.set('selectedStepIndex', stepIndex);
      this.set('selectedElementIndex', -1);
      stateToggleCallback(selectedStep, stepIndex, true);
    },

    _changeSelectedElement: function _changeSelectedElement(uuid) {
      var steps = this.get('items');
      var stepIdx = this.get('selectedStepIndex');
      var flatLvlIndex = steps[stepIdx].survey_elements_attributes.findIndex(function (elem) {
        return elem && elem.uuid === uuid;
      });
      this.set('selectedElementIndex', flatLvlIndex);
    },

    _reorderStepsConditions: function _reorderStepsConditions(prevSteps, newSteps) {
      var result = newSteps.map(function (newStep, newIdx) {
        var oldIdx = prevSteps.findIndex(function (prevStep) {
          if (newStep.uuid === prevStep.uuid) {
            return true;
          }
        });

        var stepConditionsToEdit = newSteps.filter(function (newStep) {
          return newStep.conditions.filter(function (condition) {
            return condition.kind === 'answer_in' && condition.data.answer_step === oldIdx && !condition.has_been_cmptd;
          }).length > 0;
        });
        var elemConditionsToEdit = newSteps.map(function (newStep) {
          return newStep.survey_elements_attributes.filter(function (element) {
            return element.conditions.filter(function (condition) {
              return condition.kind === 'answer_in' && condition.data.answer_step === oldIdx && !condition.has_been_cmptd;
            }).length > 0;
          });
        }).flat();

        stepConditionsToEdit.forEach(function (step) {
          step.conditions.forEach(function (condition) {
            condition.has_been_cmptd = true;
            condition.data.answer_step = newIdx;
          });
        });
        elemConditionsToEdit.forEach(function (element) {
          element.conditions.forEach(function (condition) {
            condition.has_been_cmptd = true;
            condition.data.answer_step = newIdx;
          });
        });
        return newStep;
      });

      return result;
    },

    _removeDeletedStepElementConditions: function _removeDeletedStepElementConditions(steps, stepIdx, elemIdx) {
      steps.forEach(function (step) {
        (step.conditions || []).forEach(function (condition, conditionIndex) {
          if (condition.kind === 'answer_in' && condition.data.answer_step === stepIdx && condition.data.answer_element === elemIdx) {
            step.conditions.splice(conditionIndex, 1);
          }
        });
        step.survey_elements_attributes.forEach(function (element, elementIndex) {
          (element.conditions || []).forEach(function (condition, conditionIndex) {
            if (condition.kind === 'answer_in' && condition.data.answer_step === stepIdx && condition.data.answer_element === elemIdx) {
              step.survey_elements_attributes[elementIndex].conditions.splice(conditionIndex, 1);
            }
          });
        });
      });
      return steps;
    },

    _removeDeletedStepConditions: function _removeDeletedStepConditions(steps, index) {
      steps.forEach(function (step) {
        (step.condition || []).forEach(function (condition, conditionIndex) {
          if (condition.kind === 'answer_in' && condition.data.answer_step === index) {
            step.conditions.splice(conditionIndex, 1);
          }
        });
        step.survey_elements_attributes.forEach(function (element, elementIndex) {
          (element.conditions || []).forEach(function (condition, conditionIndex) {
            if (condition.kind === 'answer_in' && condition.data.answer_step === index) {
              step.survey_elements_attributes[elementIndex].conditions.splice(conditionIndex, 1);
            }
          });
        });
      });
      return steps;
    },

    _stepsHaveMoved: function _stepsHaveMoved(reorderedSteps) {
      var steps = this.get('items');
      var response = false;

      reorderedSteps.forEach(function (step, index) {
        if (step.uuid !== steps[index].uuid) {
          response = true;
        }
      });

      return response;
    },

    _updateConditionsAfterElementChangeStep: function _updateConditionsAfterElementChangeStep(steps, initialStepIdx, initialElemIdx, newStepIdx) {
      var newElemIdx = steps[newStepIdx].survey_elements_attributes.length - 1;

      steps.forEach(function (step) {
        (step.conditions || []).forEach(function (condition) {
          if (condition.kind === 'answer_in' && condition.data.answer_step === initialStepIdx && condition.data.answer_element === initialElemIdx) {
            condition.data.answer_step = newStepIdx;
            condition.data.answer_element = newElemIdx;
          }
        });
        (step.survey_elements_attributes || []).forEach(function (element) {
          (element.conditions || []).forEach(function (condition) {
            if (condition.kind === 'answer_in' && condition.data.answer_step === initialStepIdx && condition.data.answer_element === initialElemIdx) {
              condition.data.answer_step = newStepIdx;
              condition.data.answer_element = newElemIdx;
            }
          });
        });
      });
      return steps;
    },

    _reorderStepsElementsConditions: function _reorderStepsElementsConditions(prevSteps, newElements) {
      var stepIdx = this.get('selectedStepIndex');

      newElements.forEach(function (newElem, newIdx) {
        // check all steps conditions
        prevSteps.forEach(function (step) {
          (step.conditions || []).forEach(function (condition) {
            if (condition.kind === 'answer_in' && condition.data.answer_step === stepIdx && condition.element_uuid === newElem.uuid) {
              condition.data.answer_element = newIdx;
            }
          });

          // check all elements conditions
          step.survey_elements_attributes.forEach(function (element) {
            (element.conditions || []).forEach(function (condition) {
              if (condition.kind === 'answer_in' && condition.data.answer_step === stepIdx && condition.element_uuid === newElem.uuid) {
                condition.data.answer_element = newIdx;
              }
            });
          });
        });

        // check the current step elements (new order)
        newElements.forEach(function (newElem_) {
          (newElem_.conditions || []).forEach(function (condition) {
            if (condition.kind === 'answer_in' && condition.data.answer_step === stepIdx && condition.element_uuid === newElem.uuid) {
              condition.data.answer_element = newIdx;
            }
          });
        });
      });
      set(prevSteps[stepIdx], 'survey_elements_attributes', newElements);
      return prevSteps;
    },

    _elementsHaveMoved: function _elementsHaveMoved(reorderedElements) {
      var steps = this.get('items');
      var stepIdx = this.get('selectedStepIndex');
      var elements = steps[stepIdx].survey_elements_attributes;
      var response = false;

      reorderedElements.forEach(function (elem, index) {
        if (elem.uuid !== elements[index].uuid) {
          response = true;
        }
      });

      return response;
    },

    actions: {
      dispatchAction: function dispatchAction(action) {
        var dispatcher = this.get('actionsDispatcher');

        if (dispatcher) {
          for (var _len = arguments.length, arg = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            arg[_key - 1] = arguments[_key];
          }

          dispatcher(action, arg && arg.length && arg.length === 1 ? arg[0] : arg);
        }
      },

      dispatchActionLocally: function dispatchActionLocally(idx, uuid, action) {
        if (action && typeof action === 'string') {
          this.send(action, idx, uuid);
        } else if (action && typeof action === 'object') {
          // used by openDeleteElementAlert, duplicateElement, moveElementTo
          this.send(action.action, idx, action, uuid);
        }
      },

      updateStepNameIndex: function updateStepNameIndex(index, reset) {
        var saveCallback = this.get('group.autoSaveRevisionCallback');

        if (saveCallback && reset) {
          this.send('dispatchAction', saveCallback);
        }
      },

      onClickBackButton: function onClickBackButton() {
        var backToIndex = this.get('backToIndexCallback');

        if (backToIndex) {
          backToIndex();
        }
      },

      setHoverIndex: function setHoverIndex(index) {
        this.set('hoverItemIndex', index);
      },

      reorderSteps: function reorderSteps(_, reorderedSteps) {
        var _this3 = this;

        if (this.get('selectedStepNameIndex') === -1 && this._stepsHaveMoved(reorderedSteps)) {
          (function () {
            var prevStepsOrder = _this3.get('items');
            var stepIdx = _this3.get('selectedStepIndex');
            var selectedStepUuid = prevStepsOrder[stepIdx].uuid;
            var hardCompletePage = prevStepsOrder.slice(-2)[0];
            var thankYouPage = prevStepsOrder.slice(-1)[0];
            var steps = _this3._reorderStepsConditions(prevStepsOrder, reorderedSteps);
            var newSelectedStepIndex = steps.findIndex(function (step) {
              return step.uuid === selectedStepUuid;
            });
            steps.push(hardCompletePage);
            steps.push(thankYouPage);
            _this3.set('items', steps);
            _this3.set('selectedStepIndex', newSelectedStepIndex);
            _this3.send('dispatchAction', 'refreshSteps', steps);
          })();
        }
      },

      reorderElements: function reorderElements(_, _reorderElements) {
        var _this4 = this;

        if (this._elementsHaveMoved(_reorderElements)) {
          (function () {
            var steps = _this4.get('items');
            var stepIdx = _this4.get('selectedStepIndex');
            var selectedElementIndex = _this4.get('selectedElementIndex');
            var selectedElementUuid = steps[stepIdx].survey_elements_attributes[selectedElementIndex].uuid;
            var oldSteps = copy(steps, true);
            set(steps[stepIdx], 'survey_elements_attributes', _reorderElements);
            var computedSteps = _this4._reorderStepsElementsConditions(oldSteps, _reorderElements);
            var newSelectedElementIndex = computedSteps[stepIdx].survey_elements_attributes.findIndex(function (element) {
              return element.uuid === selectedElementUuid;
            });
            _this4.set('items', computedSteps);
            _this4.set('selectedElementIndex', newSelectedElementIndex);
            _this4.send('dispatchAction', 'refreshSteps', computedSteps);
          })();
        }
      },

      onStepNameChanged: function onStepNameChanged(index, value) {
        this._stepNameChanged(index, value);
      },

      openDeleteStepAlert: function openDeleteStepAlert(stepIndex) {
        this.set('stepIndexToDelete', stepIndex);
        this.set('deleteStepAlertIsOpen', true);
      },

      openDeleteElementAlert: function openDeleteElementAlert(index, _, uuid) {
        var steps = this.get('items');
        var stepIdx = this.get('selectedStepIndex');
        var flatLvlIndex = steps[stepIdx].survey_elements_attributes.findIndex(function (elem) {
          return elem && elem.uuid === uuid;
        });

        this.set('elementIndexToDelete', flatLvlIndex);
        this.set('deleteElementAlertIsOpen', true);
      },

      duplicateStep: function duplicateStep(index) {
        var steps = this.get('items');
        var newSteps = copy(steps, true);
        var duplicateStep = copy(steps[index], true);

        duplicateStep.name += ' - copy';
        duplicateStep.uuid = crypto.randomUUID();
        duplicateStep.survey_elements_attributes.forEach(function (element) {
          return delete element['new_question_id'];
        });
        newSteps.splice(index + 1, 0, duplicateStep);
        var res = this._reorderStepsConditions(steps, newSteps);
        this.send('dispatchAction', 'refreshSteps', res);
        this.set('hoverItemIndex', index + 1);
        this.set('selectedStepIndex', index + 1);
        this.set('selectedElementIndex', -1);
      },

      duplicateElement: function duplicateElement(index) {
        var _this5 = this;

        var steps = this.get('items');
        var newSteps = copy(steps, true);
        var stepIdx = this.get('selectedStepIndex');
        var duplicateElement = copy(steps[stepIdx].survey_elements_attributes[index], true);

        duplicateElement.name += ' - copy';
        duplicateElement.uuid = crypto.randomUUID();
        delete duplicateElement['new_question_id'];

        if (['single_choice', 'multiple_choice'].includes(duplicateElement.kind)) duplicateElement.content.options = {};

        newSteps[stepIdx]['survey_elements_attributes'].splice(index + 1, 0, duplicateElement);
        var computedSteps = this._reorderStepsElementsConditions(steps, newSteps[stepIdx]['survey_elements_attributes']);
        this.set('items', computedSteps);
        this.notifyPropertyChange('items');
        this.send('dispatchAction', 'refreshSteps', computedSteps);
        this.set('hoverItemIndex', -1);
        setTimeout(function () {
          return _this5.set('selectedElementIndex', index + 1);
        }, 1500);
      },

      deleteStep: function deleteStep() {
        var steps = this.get('items');
        var newSteps = copy(steps, true);
        var stepIndex = this.get('stepIndexToDelete');

        newSteps.splice(stepIndex, 1);
        newSteps = this._removeDeletedStepConditions(newSteps, stepIndex);
        steps = this._reorderStepsConditions(steps, newSteps);
        var stepsLength = steps.length;
        this.set('hoverItemIndex', -1);
        this.send('dispatchAction', 'refreshSteps', steps);
        if (stepsLength > 2) {
          this.set('selectedStepIndex', stepsLength - 3);
          this.set('selectedElementIndex', -1);
        } else {
          this.set('selectedStepIndex', 0);
          this.set('selectedElementIndex', -1);
        }
        this.set('deleteStepAlertIsOpen', false);
      },

      cancelDeleteStep: function cancelDeleteStep() {
        this.set('stepIndexToDelete', -1);
        this.set('hoverItemIndex', -1);
        this.set('deleteStepAlertIsOpen', false);
      },

      cancelDeleteElement: function cancelDeleteElement() {
        this.set('elementIndexToDelete', -1);
        this.set('deleteElementAlertIsOpen', false);
      },

      deleteElement: function deleteElement() {
        var deleteConditions = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        var steps = this.get('items');
        var newSteps = copy(steps);
        var stepIdx = this.get('selectedStepIndex');
        var elemIndex = this.get('elementIndexToDelete');

        newSteps[stepIdx]['survey_elements_attributes'].splice(elemIndex, 1);
        if (deleteConditions) newSteps = this._removeDeletedStepElementConditions(newSteps, stepIdx, elemIndex);
        var res = this._reorderStepsElementsConditions(steps, newSteps[stepIdx]['survey_elements_attributes']);
        this.send('dispatchAction', 'refreshSteps', res);
        this.set('hoverItemIndex', -1);
        this.set('selectedElementIndex', newSteps[stepIdx].survey_elements_attributes.length - 1);
        this.set('deleteElementAlertIsOpen', false);
      },

      editStepName: function editStepName(stepIndex) {
        var index = stepIndex == null ? this.get('hoverItemIndex') : stepIndex;
        this.set('selectedStepNameIndex', index);

        setTimeout(function () {
          var editableLabel = document.getElementById('step-name-editable-label-' + index);

          if (editableLabel) {
            editableLabel.click();
          }
        }, 300);
      },

      addPage: function addPage() {
        var steps = this.get('items');
        var newSteps = copy(steps, true);
        var newPage = { uuid: crypto.randomUUID(), name: this.get('i18n').t('words.new_page').string, kind: 'content', conditions: [], 'survey_elements_attributes': [] };

        newSteps.splice(newSteps.length - 2, 0, newPage);
        var res = this._reorderStepsConditions(steps, newSteps);
        this.send('dispatchAction', 'refreshSteps', res);
        this.set('items', res);
        this.set('selectedStepIndex', res.length - 3);
        this.set('selectedElementIndex', -1);
      },

      addThankYouPageElement: function addThankYouPageElement(kind) {
        this.send('addElement', kind, true);
      },

      addElement: function addElement(kind) {
        var thankYouPage = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var newElem = { uuid: crypto.randomUUID(), name: kind.text, kind: kind.key, conditions: [], content: {} };
        var steps = this.get('items');
        var newSteps = copy(steps, true);
        var stepIdx = thankYouPage ? this.get('thankYouPageIndex') : this.get('selectedStepIndex');

        if (kind.key === 'rating') {
          newElem.content.rating_scale_id = kind.id;
          newElem.content.legend = {};
          newElem.content.style = { rating_legend: 'rating-legend-inline', rating_legend_top: 'rating-hidden' };
        }
        if (kind.key.includes('_redirect_btn')) {
          newElem.kind = 'redirect_btn';
          newElem.content.type = kind.key.split('_')[0];
        }
        if (kind.key.includes('assign_segment_')) {
          newElem.kind = 'assign_segment';
          newElem.content.assignment_type = kind.key.split('assign_segment_')[1];
        }
        if (kind.key === 'group') {
          newElem.content.children = [];
          newElem.content.child_indexes = [];
          newElem.content.child_indexes_info = [];
        }

        newSteps[stepIdx].survey_elements_attributes.push(newElem);
        var res = this._reorderStepsElementsConditions(steps, newSteps[stepIdx]['survey_elements_attributes']);
        this.set('selectedElementIndex', newSteps[stepIdx].survey_elements_attributes.length - 1);
        this.send('dispatchAction', 'refreshSteps', res);
      },

      onMouseUp: function onMouseUp(stepIndex, event) {
        var _this6 = this;

        this.set('clicks', this.get('clicks') + 1);
        if (this.get('clicks') === 1) {
          setTimeout(function () {
            var clicks = _this6.get('clicks');
            var selectedStepNameIndex = _this6.get('selectedStepNameIndex');
            _this6.set('clicks', 0);
            if (_this6.get("mouseY") === event.y && clicks === 1 && selectedStepNameIndex == -1) {
              _this6._changeSelectedStep(stepIndex);
            }
          }, 500);
        }
      },

      onMouseDown: function onMouseDown(event) {
        this.set("mouseY", event.y);
      },

      toggleStepGroup: function toggleStepGroup(index) {
        var saveDraftCallback = this.get('saveDraftCallback');
        if (saveDraftCallback) saveDraftCallback();
        this._changeSelectedStep(index);
      },

      setSelectedElement: function setSelectedElement(uuid, fromGroup) {
        var saveDraftCallback = this.get('saveDraftCallback');
        if (saveDraftCallback) saveDraftCallback();
        this._changeSelectedElement(uuid, fromGroup == true);
      },

      toggleGroupElement: function toggleGroupElement(uuid) {
        this._changeSelectedElement(uuid, true);
      },

      openAddElementModal: function openAddElementModal() {
        var saveDraftCallback = this.get('saveDraftCallback');
        if (saveDraftCallback) saveDraftCallback();
        this.set('addElementModalIsOpen', true);
      },

      closeAddElementModal: function closeAddElementModal() {
        this.set('addElementModalIsOpen', false);
      },

      moveElementTo: function moveElementTo(_, step) {
        var steps = this.get('items');
        var newSteps = copy(steps);
        var stepIdx = this.get('selectedStepIndex');
        var elemIdx = this.get('selectedElementIndex');
        var elem = copy(steps[stepIdx].survey_elements_attributes[elemIdx], true);
        this.set('elementIndexToDelete', elemIdx);
        this.send('deleteElement', false);
        newSteps[step.position].survey_elements_attributes.push(elem);
        // check source step / element conditions
        this.set('selectedStepIndex', step.position);
        this.set('selectedElementIndex', newSteps[step.position].survey_elements_attributes.length - 1);
        var res = this._updateConditionsAfterElementChangeStep(newSteps, stepIdx, elemIdx, step.position);
        this.send('dispatchAction', 'refreshSteps', res);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 */