define('due-dashboard/components/due-dropdown/due-dropdown-multilevel-item', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;

  /**
   * @module due-dropdown-multilevel-item
   * @argument {Object} option (Required) Option to display
   * @argument {Function} onClickOption (Required) Trigger function used when click on the option
   * @argument {Function} onOver (Optional) Trigger when mouse is over item
   * @argument {String} keyDisplay (Optional) Key used to display text
   * @argument {Integer} index (Required) Option position in array
   * @argument {Integer} focusItem (Required) Current focused item
   * @argument {String} keySeparator (Optional) Key used to split options as sub selectors from option name
   * @argument {Integer} maxInlineSize (Optional) Max char for inline title
   */
  exports['default'] = Component.extend({
    tagName: 'li',
    classNames: ['ddm-i-container'],
    classNameBindings: ['disabled:disabled', 'isFocused:focused'],

    /* ### Optional props ### */
    keySeparator: ' > ',
    maxInlineSize: 40,
    keyDisplay: 'title',

    /* ### Private props ### */
    prefix: '',
    prefixTooLong: false,

    /**
     * - Force disabled option
    */
    didRender: function didRender() {
      var disabled = this._forceDisableOption(this.get('option'));
      this.set('disabled', disabled);
    },

    isParent: computed('option', function () {
      return this.get('option.parent');
    }),

    hasChildren: computed('option', function () {
      var option = this.get('option');
      return option.options && !option.parent;
    }),

    hasIcon: computed('option', function () {
      return !this.get('option.parent') && !!this.get('option.icon') || this.get('option.parent') && !this.get('hideIconOnParent') && !!this.get('option.icon');
    }),

    isDisabled: computed('disabled', function () {
      var option = this.get('option');
      return this.get('disabled') || option.disabled;
    }),

    isFocused: computed('focusItem', 'index', function () {
      return this.get('focusItem') === this.get('index');
    }),

    isOptionalLevel: computed('option.optionalLevel', function () {
      return this.get('option.optionalLevel');
    }),

    /**
     * Build text to display
     * - Prefix (Text before separator)
     * - Prefix < 40 = Display with column
     */
    computedText: computed('option', 'keyDisplay', 'keySeparator', 'maxInlineSize', function () {
      if (!this.get('keyDisplay')) return this.get('option');
      var text = this.get('option.' + this.get('keyDisplay'));
      var keySeparator = this.get('keySeparator');
      var maxInlineSize = this.get('maxInlineSize');

      if (!text || typeof text !== 'string' || !text.includes(keySeparator)) return text;

      var indexSep = text.lastIndexOf(keySeparator) + keySeparator.length,
          prefix = text.substr(0, indexSep),
          sufix = text.substr(indexSep);

      this.set('prefixTooLong', prefix.length + sufix.length > maxInlineSize);
      this.set('prefix', prefix);

      return sufix;
    }),

    styleOptionColor: computed('option.color', {
      get: function get() {
        return htmlSafe('color: ' + this.get('option.color') + ';');
      }
    }),

    /**
     * Trigger simple click on the option
     */
    click: function click() {
      var option = this.get('option'),
          onClickOption = this.get('onClickOption');
      // let disabled = option.get ? this.get('disabled') : option['disabled'];
      if (this.get('isDisabled')) return false;
      onClickOption(option);
      return true;
    },

    /**
     * Trigger when mouse is over the option
     */
    mouseEnter: function mouseEnter() {
      var onOver = this.get('onOver');
      if (onOver) onOver(this.get('index'));
    },

    /**
     * Check if the option is disabled or all its child are disabled
     * @param {Arrary} option Option item
     * @return {Boolean} True: Item and all child disabled else false
     */
    _forceDisableOption: function _forceDisableOption(option) {
      var _this = this;

      if (option.optionalLevel || option.parent) return false;
      if (option.options) {
        var res = option.options.map(function (option) {
          return _this._forceDisableOption(option);
        });
        return !res.includes(false);
      }

      return option.disabled || false;
    }
  });
});
/**
 * @component {{due-dropdown-multilevel}}
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @author Eliott Martin <eliott@diduenjoy.com> (Edit) (09/11/2021)
 * @summary {{due-dropdown-multilevel-item}} multi level item dropdown pannel
 * @figma https://www.figma.com/file/2rgm0uaqvk0x9Pcam4yDQb/Diduenjoy-Design-System?node-id=622%3A0
 */