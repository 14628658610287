define('due-dashboard/components/due-table/elements/-number-trend', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-number-trend'],

    shareTranslation: computed('compValue.share', function () {
      var translation = this.get('i18n').t('components.reports.array.share').toString();
      return translation.replace("#total", this.get('compValue.share').toFixed(0));
    }),

    kpiTypeRate: computed('value', function () {
      return this.get('value.kpiTypeRate');
    }),

    hideValue: computed('value', function () {
      return this.get('value.hideValue');
    }),

    noTrend: computed('value', function () {
      return this.get('value.noTrend');
    }),

    trendTextTooltip: computed('value', function () {
      return this.get('value.trendTextTooltip');
    }),

    compValue: computed('value', function () {
      return {
        number: this.get('value.number'),
        rate: this.get('value.rate'),
        share: this.get('value.share'),
        trend: this.get('value.trend')
      };
    })

  });
});