define('due-dashboard/services/current-account', ['exports', 'ember', 'due-dashboard/config/environment'], function (exports, _ember, _dueDashboardConfigEnvironment) {
  var service = _ember['default'].inject.service;
  var RSVP = _ember['default'].RSVP;
  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  exports['default'] = Service.extend({
    session: service(),
    store: service(),
    newCompany: false,

    switchProfile: function switchProfile(ressource_name, custom_ft) {
      var profile = this.get('content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return custom_ft(this.get('rights.' + ressource_name + '_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    },

    setRights: function setRights() {
      var _this = this;

      ['templates', 'tags', 'graphs', 'rating_scales', 'fonts'].forEach(function (ressource) {
        _this['canEdit' + ressource.capitalize()] = computed('content.profile', function () {
          return _this.switchProfile(ressource, function (right) {
            return right === 'all' || right === 'write';
          });
        });
      });
    },

    canUseAnalysis: computed('content.profile', 'rights', 'rights.analysis_rights', function () {
      return this.get('content.profile') !== 'custom' || this.get('rights.analysis_rights') !== "none";
    }),

    canUseVerbatims: computed('content.profile', 'rights', 'rights.verbatims_rights', function () {
      return this.get('content.profile') !== 'custom' || this.get('rights.verbatims_rights') !== "none";
    }),

    canAccessGraphs: computed('content.profile', 'rights', 'rights.graphs_rights', function () {
      return this.get('content.profile') !== 'custom' || this.get('rights.graphs_rights') !== "none";
    }),

    canAccessCustomReports: computed('content.profile', 'rights', 'rights.custom_reports_rights', function () {
      return this.get('content.profile') !== 'custom' || this.get('rights.custom_reports_rights') !== "none";
    }),

    canUseTags: computed('rights.tags_rights', function () {
      if (this.get('content.profile') != 'custom') {
        return true;
      }
      return this.get('rights.tags_rights') !== 'read' && this.get('rights.tags_rights') !== 'none';
    }),

    canSeeTags: computed('rights.tags_rights', function () {
      return this.get('content.profile') != 'custom' || this.get('rights.tags_rights') !== 'none';
    }),

    canUseAutoTags: computed('rights.auto_tags_rights', function () {
      if (this.get('content.profile') != 'custom') {
        return true;
      }
      return this.get('rights.auto_tags_rights') === 'assign';
    }),

    canSeeAutoTags: computed('rights.auto_tags_rights', function () {
      return this.get('content.profile') != 'custom' || this.get('rights.auto_tags_rights') !== 'none';
    }),

    smsRights: computed('content.company.max_sms_per_feedback', function () {
      return !!this.get('content.company.max_sms_per_feedback');
    }),

    useNewSurvey: computed('content.company.enable_new_survey_dashboard', function () {
      return !!this.get('content.company.enable_new_survey_dashboard');
    }),

    hide_email_in_export: computed('content.company.hide_email_in_export', function () {
      return !!this.get('content.company.hide_email_in_export');
    }),

    isAdmin: computed('content.profile', function () {
      return this.get('content.profile') === 'admin';
    }),

    canManageUsers: computed('content.profile', 'content.account_custom_profile_right.users_rights', function () {
      return this.get('isAdmin') || this.get('content.account_custom_profile_right.users_rights') === 'manage';
    }),

    canCreateUsers: computed('content.profile', 'canManageUsers', 'content.account_custom_profile_right.user_rights_management', function () {
      return this.get('isAdmin') || this.get('canManageUsers') && this.get('content.account_custom_profile_right.user_rights_management').includes('create');
    }),

    canEditUsers: computed('content.profile', 'canManageUsers', 'content.account_custom_profile_right.user_rights_management', function () {
      return this.get('isAdmin') || this.get('canManageUsers') && this.get('content.account_custom_profile_right.user_rights_management').includes('update');
    }),

    canDeleteUsers: computed('content.profile', 'canManageUsers', 'content.account_custom_profile_right.user_rights_management', function () {
      return this.get('isAdmin') || this.get('canManageUsers') && this.get('content.account_custom_profile_right.user_rights_management').includes('delete');
    }),

    canUseSummaries: computed('content.profile', 'content.account_custom_profile_right.summaries_rights', 'content.zone', function () {
      return this.get('content.company.enable_summaries') && this.get('content.zone') !== 'China' && (this.get('content.profile') !== 'custom' || this.get('content.account_custom_profile_right.summaries_rights'));
    }),

    isReadOnly: computed('content.profile', function () {
      return this.get('content.profile') === 'read_only';
    }),

    isFromChina: computed('content.zone', function () {
      return this.get('content.zone') === 'China';
    }),

    hasReportCustomRight: computed('reportCustomRights', function () {
      var rcrs = this.get('reportCustomRights');
      return rcrs && rcrs.length > 0;
    }),

    reportRightsList: computed('hasReportCustomRight', 'reportCustomRights', function () {
      if (!this.get('reportCustomRights')) {
        return [];
      }
      return this.get('reportCustomRights').map(function (r) {
        return r.get('report.id');
      });
    }),

    canAccessStandardReport: computed('content.company', function () {
      var userRoleAccessControl = this.get('content.company.report_config.user_role_access_control') || [],
          userRoleId = this.get('content.account_custom_profile_right.id') || this.get('content.profile');
      return this.get('content.company.ff_standard_report') && userRoleAccessControl.includes(userRoleId);
    }),

    standardReportSidebar: computed('content.company', function () {
      var sidebars = this.get('content.company.report_config.sidebars') || {},
          userRoleId = this.get('content.account_custom_profile_right.id') || this.get('content.profile');
      var sidebar = Object.values(sidebars).find(function (sidebar) {
        return (sidebar.user_roles || []).includes(userRoleId);
      });
      return sidebar || {};
    }),

    showSurveyPopin: function showSurveyPopin(account) {
      var wait_ms = 5000;
      var survey_id = account.get('survey_popin');
      var email = account.get('email');

      window.setTimeout(function (email, survey_id) {
        if (window._diduenjoy !== undefined) {
          return;
        }

        window._diduenjoy = {
          params: {
            ref_mail: email,
            locale: account.get('language.tag').toLowerCase(),
            company_name: account.get('company.name')
          },
          domain: 'com',
          delay: 0,
          surveyId: survey_id
        };

        window.addEventListener("DiduenjoySurveyLoaded", function () {
          Diduenjoy.surveyPopup();
        });
        (function (doc, script) {
          var js,
              fjs = doc.getElementsByTagName(script)[0],
              add = function add(url, id) {
            if (doc.getElementById(id)) {
              return;
            }js = doc.createElement(script);
            js.src = url;id && (js.id = id);fjs.parentNode.insertBefore(js, fjs);
          };add(('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.diduenjoy.' + _diduenjoy.domain + '/libs/due-popup.min.js', 'diduenjoypopup');
        })(document, 'script');

        account.set('survey_popin', 'disable');
        account.save();
      }, wait_ms, email, survey_id);
    },

    loadCurrentUser: function loadCurrentUser() {
      var _this2 = this;

      this.set('isLoading', true);
      return new RSVP.Promise(function (resolve, reject) {
        if (_this2.get('session.isAuthenticated')) {
          return _this2.get('store').queryRecord('account', { me: true }).then(function (account) {
            if (_dueDashboardConfigEnvironment['default'].EmberENV.ENABLE_SKALIN) {
              ska(function (skalin) {
                skalin.identity({ email: account.get('email') });
              });
            }
            _this2.set('content', account);
            _this2.set('resources', _this2.get('store').queryRecord('resources', {}));

            // show survey popin if needed
            if (account.get('survey_popin')) {
              _this2.showSurveyPopin(account);
            }
            _this2.get('store').query('language', { filter: { account_id: account.get('id') } }).then(function (dashboardLanguages) {
              _this2.set('dashboardLanguages', dashboardLanguages.toArray());
            });
            account.get('language').then(function () {
              _this2.get('store').query('language', { filter: { account_id: account.id } }).then(function (languages) {
                _this2.set('companyLanguages', languages);
                if (account.get('profile') === 'custom') {
                  _this2.get('store').findAll('account-custom-profile-right').then(function (profile) {
                    _this2.set('rights', profile.content[0].record);
                    _this2.set('isLoading', false);
                    _this2.setRights();
                    _this2.get('store').query('report-custom-right', { filter: { account_custom_profile_right_id: profile.content[0].id } }).then(function (rcr) {
                      _this2.set('reportCustomRights', rcr.toArray());
                    });
                    resolve();
                  });
                } else {
                  _this2.set('isLoading', false);
                  _this2.setRights();
                  resolve();
                }
              });
            });
          }, reject);
        } else {
          _this2.set('isLoading', false);
          _this2.setRights();
          resolve();
        }
      });
    }
  });
});