define('due-dashboard/components/v2/-range-slider', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var guidFor = _ember['default'].guidFor;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({
    classNames: ['due-slider'],
    compValue: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('elementGUID', 'due-slider-' + guidFor(this));
      this.set('compValue', this.get('value'));
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('compValue', this.get('value'));
      this._updateFillContainer(this.get('compValue'));
    },

    _updateFillContainer: function _updateFillContainer(value) {
      var _this = this;

      var min = parseInt(this.get('min'));
      var max = parseInt(this.get('max'));
      value = parseInt(value);
      var slider = document.getElementById(this.elementGUID);
      var input = document.getElementById('input-' + this.elementGUID);

      if (value < min) value = min;else if (value > max) value = max;

      var updateStyle = function updateStyle(width) {
        slider.style.width = width + '%';
        if (input) input.value = value;
        _this.set('compValue', value.toString());
      };

      if (value === min) updateStyle(slider.style.width = 0);else slider.style.width = updateStyle((value - min) / (max - min) * 100);
    },

    _onChangeCallback: function _onChangeCallback(value) {
      var callback = this.get('onChange');
      if (callback) callback(value.toString());
    },

    actions: {
      onChange: function onChange(event) {
        var value = event.target.value;
        this._updateFillContainer(value);
        debounce(this, this._onChangeCallback, value, 600);
      },

      onSelectSuffixOption: function onSelectSuffixOption(value) {
        var suffixCallback = this.get('onSelectSuffixOption');
        if (suffixCallback) suffixCallback(value);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 */