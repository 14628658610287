define("due-dashboard/templates/components/icons/-csat", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/icons/-csat.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "21");
        dom.setAttribute(el1, "height", "9");
        dom.setAttribute(el1, "viewBox", "0 0 21 9");
        dom.setAttribute(el1, "fill", "none");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M4.52075 0.202V8.26H2.96675V1.798L1.19675 2.896L0.518748 1.798L3.12275 0.202H4.52075ZM6.13475 7.282V8.5H0.956748V7.282H6.13475ZM7.92406 5.374V4.114H12.9881V5.374H7.92406ZM20.1054 0.202L19.9194 1.366H16.7994V3.442C17.0194 3.334 17.2394 3.258 17.4594 3.214C17.6794 3.17 17.8914 3.148 18.0954 3.148C18.5314 3.148 18.9234 3.254 19.2714 3.466C19.6234 3.678 19.8994 3.984 20.0994 4.384C20.3034 4.78 20.4054 5.256 20.4054 5.812C20.4054 6.368 20.2754 6.864 20.0154 7.3C19.7594 7.732 19.3994 8.07 18.9354 8.314C18.4714 8.558 17.9274 8.68 17.3034 8.68C16.7394 8.68 16.2374 8.576 15.7974 8.368C15.3614 8.16 14.9874 7.88 14.6754 7.528L15.5994 6.67C15.8194 6.926 16.0654 7.12 16.3374 7.252C16.6094 7.38 16.9014 7.444 17.2134 7.444C17.6894 7.444 18.0674 7.3 18.3474 7.012C18.6274 6.72 18.7674 6.322 18.7674 5.818C18.7674 5.438 18.7134 5.136 18.6054 4.912C18.4974 4.688 18.3494 4.526 18.1614 4.426C17.9734 4.326 17.7574 4.276 17.5134 4.276C17.3294 4.276 17.1474 4.298 16.9674 4.342C16.7914 4.382 16.6014 4.452 16.3974 4.552H15.2874V0.202H20.1054Z");
        dom.setAttribute(el2, "fill", "currentColor");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});