define('due-dashboard/components/ratingscale-type-selector', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    SCALE_TYPES: [{ key: 'nps', text: 'words.nps' }, { key: 'csat', text: 'words.csat' }, { key: 'custom', text: 'words.custom' }],

    selectedScaleType: { key: 'nps', text: 'words.nps' },

    actions: {
      changeScaleType: function changeScaleType(scaleType) {
        this.set('selectedScaleType', scaleType);
        this.onChangeScaleType(scaleType);
      }
    }
  });
});