define('due-dashboard/components/v2/libraries/surveys/navbar/elements/common/closed-questions', ['exports'], function (exports) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _getQuestionSource(condition) {
    var _this = this;

    var questionSource = [];
    var questions = this.get('group.questionsList');
    if (condition.kind === 'answer_in') {
      (function () {
        var step = condition.data.answer_step;
        var element = condition.data.answer_element;
        var question = questions.filter(function (question) {
          return question.step === step && question.element === element;
        })[0];

        switch (question.kind) {
          case 'single_select':
          case 'multiple_select':
            questionSource.push.apply(questionSource, _toConsumableArray(question.content.options.map(function (r) {
              return { key: r['key'], text: r['value'] };
            })));
            break;
          case 'rating':
            var rating_scale = _this.get('store').peekRecord('rating_scale', question.content.rating_scale_id);
            if (rating_scale) {
              questionSource.push.apply(questionSource, _toConsumableArray(rating_scale.get('ratings').map(function (r) {
                return { key: r.get('score').toString(), text: r.get('score').toString() };
              })));
              if (question.content.skipable) questionSource.push({ key: 'skipped', text: _this.get('i18n').t('words.na') });
            }
            break;
          default:
            questionSource.clear();
        }
      })();
    }
    return questionSource;
  }

  exports._getQuestionSource = _getQuestionSource;
});