define('due-dashboard/components/reports/summary/-verbatim', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: '',
    is_active: false,
    indexActive: { 0: 'active' },
    smileys: { negative: 'sad', positive: 'slightly_smilling' },
    colors: { negative: 'red', positive: 'green' },

    smiley: computed('data', 'data.type', function () {
      var _type = this.get('data.type');
      return this.get('smileys')[_type] || 'neutral_face';
    }),

    smileyColor: computed('data', 'data.type', function () {
      var _type = this.get('data.type');
      return this.get('colors')[_type] || 'grey';
    }),

    is_active_class: computed('is_active', function () {
      return this.get('is_active') ? 'active' : '';
    }),

    title: computed('data', function () {
      if (this.get('data.title')) {
        return this.get('data.title');
      } else if (this.get('data.subject')) {
        return this.get('data.subject');
      }
    }),

    actions: {
      toogleActive: function toogleActive() {
        this.toggleProperty('is_active');
      }
    }
  });
});