define('due-dashboard/components/v2/libraries/surveys/navbar/translations/elements/-generic', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['survey-translations-element'],

    SURVEY_ELEMENTS: {
      'short_text_input': [{ name: 'label', field: 'label', placeholder: 'label' }, { name: 'placeholder', field: 'placeholder', placeholder: 'placeholder' }],
      'long_text_input': [{ name: 'label', field: 'label', placeholder: 'label' }, { name: 'placeholder', field: 'placeholder', placeholder: 'placeholder' }],
      'title': [{ name: 'text', field: 'text', placeholder: 'text' }],
      'text': [{ name: 'text', field: 'text', placeholder: 'text' }],
      'prev_next_btns': [{ sidebarName: 'previous_button', name: 'text', field: 'previous_button_text', placeholder: 'previous_button_label' }, { sidebarName: 'next_button', name: 'text', field: 'next_button_text', placeholder: 'next_button_label' }],
      'redirect_btn': [{ name: 'text', field: 'text', placeholder: 'text' }]
    },

    sidebar: computed(function () {
      return this.SURVEY_ELEMENTS[this.get('surveyElement')['kind']] || [];
    }),

    languages: computed('languagesToDisplay', 'translations', function () {
      var _this = this;

      var languages = [];
      var mainLanguageTag = this.get('mainLanguage.tag');
      this.get('languagesToDisplay').forEach(function (lang) {
        var language = { name: lang, fields: [] };
        if (lang == mainLanguageTag) {
          (function () {
            var surveyElement = _this.get('surveyElement');
            language.mainLanguage = true;
            _this.SURVEY_ELEMENTS[surveyElement['kind']].forEach(function (element) {
              language.fields.push({
                name: element.field,
                value: surveyElement['content'][element.field],
                placeholder: element.placeholder
              });
            });
          })();
        } else {
          (function () {
            language.mainLanguage = false;
            var surveyElement = _this.get('surveyElement');
            var element = _this._getElement(lang.toLowerCase());
            _this.SURVEY_ELEMENTS[surveyElement['kind']].forEach(function (f) {
              language.fields.push({
                name: f.field,
                value: _this._readNestedObject(element, f.field),
                placeholder: f.placeholder
              });
            });
          })();
        }
        languages.push(language);
      });
      return languages;
    }),

    _getElement: function _getElement(lang) {
      var translations = this.get('translations');
      var stepPosition = String(this.get('stepPosition'));
      var elementPosition = String(this.get('surveyElement.position'));
      if (translations == undefined) {
        return {};
      }
      return (((translations[lang] || {})['elements'] || {})[stepPosition] || {})[elementPosition];
    },

    _ensureExistence: function _ensureExistence(lang, stepPosition, elementPosition) {
      var translations = this.get('translations') || {};
      if (translations[lang] == undefined) {
        translations[lang] = {};
      }
      if (translations[lang]['elements'] == undefined) {
        translations[lang]['elements'] = {};
      }
      if (translations[lang]['elements'][stepPosition] == undefined) {
        translations[lang]['elements'][stepPosition] = {};
      }
      if (translations[lang]['elements'][stepPosition][elementPosition] == undefined) {
        translations[lang]['elements'][stepPosition][elementPosition] = {};
      }
      return translations;
    },

    _updateTranslations: function _updateTranslations(translationsElement, elementPath, value) {
      var _elementPath$split = elementPath.split('.');

      var _elementPath$split2 = _toArray(_elementPath$split);

      var head = _elementPath$split2[0];

      var rest = _elementPath$split2.slice(1);

      return _extends({}, translationsElement, _defineProperty({}, head, rest.length ? this._updateTranslations(translationsElement[head], rest.join('.'), value) : value));
    },

    _readNestedObject: function _readNestedObject(translationsElement, elementPath) {
      var _elementPath$split3 = elementPath.split('.');

      var _elementPath$split32 = _toArray(_elementPath$split3);

      var head = _elementPath$split32[0];

      var rest = _elementPath$split32.slice(1);

      if (translationsElement == undefined || translationsElement[head] == undefined) {
        return undefined;
      }
      return rest.length ? this._readNestedObject(translationsElement[head], rest.join('.')) : translationsElement[head];
    },

    actions: {
      setTranslations: function setTranslations(lg, elementPath, value) {
        var lang = lg.toLowerCase();
        var stepPosition = String(this.get('stepPosition'));
        var elementPosition = String(this.get('surveyElement.position'));
        var translations = this._ensureExistence(lang, stepPosition, elementPosition);
        var translationsElement = translations[lang]['elements'][stepPosition][elementPosition];
        translations[lang]['elements'][stepPosition][elementPosition] = this._updateTranslations(translationsElement, elementPath, value);
        this.set('translations', translations);
        this.sendAction('updateSurveyTranslations', translations);
      }
    }
  });
});