define('due-dashboard/controllers/settings/users/index', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var _Ember$computed = _ember['default'].computed;
  var filterBy = _Ember$computed.filterBy;
  var alias = _Ember$computed.alias;
  var _Ember$inject = _ember['default'].inject;
  var controller = _Ember$inject.controller;
  var service = _Ember$inject.service;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    currentAccount: service(),
    settings: controller(),
    loadedAccounts: filterBy('model.all_accounts', 'isLoaded', true),
    filteredAccounts: filterBy('loadedAccounts', 'isNew', false),
    accounts: alias('filteredAccounts'),
    limitedModal: {
      trigger: {
        textKey: 'settings.users.add_user',
        disabled: false,
        buttonIcon: 'tio-add',
        buttonClass: 'limited',
        buttonIntent: 'primary'
      },
      title: { textKey: 'settings.users.max_user_title', 'class': '' },
      content: { textKey: 'settings.users.max_user_text', 'class': '' },
      cancelBtn: { textKey: 'words.cancel', intent: 'secondary', 'class': '' },
      buttons: [{ action: 'mailSupport', textKey: 'digest.limitation_modal.contact_action', buttonClass: '', intent: 'primary' }]
    },

    currentStep: 1,
    filterCurrentStep: 1,
    filteredUsers: [],
    searchField: '',
    isLoading: false,
    availableExportFormats: ['xlsx'],

    canCreate: _ember['default'].computed('accounts.length', 'model.company.max_account', 'currentAccount.canCreateUsers', function () {
      if (this.get('model.company.max_account') != 0 && this.get('accounts.length') >= this.get('model.company.max_account')) {
        return false;
      }
      if (this.get('currentAccount.canCreateUsers') === false) {
        return false;
      } else {
        return true;
      }
    }),

    canShowZone: _ember['default'].computed(function () {
      var account = this.get('currentAccount.content');
      return account.get('company.replicate_to_china') && account.get('zone') == 'Europe';
    }),

    exportName: _ember['default'].computed(function () {
      return this.get('currentAccount.content.company.name') + '_user_export_' + moment().utc().format('YYYYMMDDHHmmss');
    }),

    updateScroll: function updateScroll() {
      var _this2 = this;

      var leftToQuery = arguments.length <= 0 || arguments[0] === undefined ? 50 : arguments[0];

      this.set('currentStep', this.get('currentStep') + 1);
      var filters = {};
      filters.page = { number: this.get('currentStep'), size: 50 };
      var all_accounts = this.get('model.all_accounts').toArray();
      this.get('store').query('account', filters).then(function (results) {
        var difference = results.toArray().filter(function (x) {
          return !all_accounts.includes(x);
        });
        all_accounts = [].concat(_toConsumableArray(all_accounts), _toConsumableArray(difference));
        leftToQuery = leftToQuery - difference.length;
        _this2.set('model.all_accounts', all_accounts);
        if (leftToQuery < 0 || results.toArray().length === 0) {
          _this2.set('isLoading', false);
        } else {
          _this2.updateScroll(leftToQuery);
        }
      });
    },

    filterUsers: function filterUsers() {
      var _this3 = this;

      var term = this.get('searchField');
      this.set('filterCurrentStep', this.get('filterCurrentStep') + 1);
      var filters = { filter: { email: term } };
      filters.page = { number: this.get('filterCurrentStep'), size: 50 };
      var filteredUsers = this.get('filteredUsers');
      this.get('store').query('account', filters).then(function (results) {
        filteredUsers = [].concat(_toConsumableArray(filteredUsers), _toConsumableArray(results.toArray()));
        // Race condition with lazy loading and searching could insert
        // the same user(s) in filteredUsers
        _this3.set('filteredUsers', _this3.ensureUniqueUsers(filteredUsers));
        _this3.set('isLoading', false);
      });
    },

    ensureUniqueUsers: function ensureUniqueUsers(filteredUsers) {
      var uniqueUsers = {};
      filteredUsers.forEach(function (user) {
        if (uniqueUsers[user.get('id')] == null) {
          uniqueUsers[user.get('id')] = user;
        }
      });
      return Object.values(uniqueUsers);
    },

    _closeProgressModal: function _closeProgressModal() {
      this.set('progressModalIsOpened', false);
    },

    actions: {
      triggerAction: function triggerAction(action) {
        this.send(action);
      },

      reloadAccount: function reloadAccount() {
        this.set('isLoading', true);
        if (this.get('searchField').length > 0) {
          _ember['default'].run.debounce(this, this.filterUsers, 200);
        } else {
          _ember['default'].run.debounce(this, this.updateScroll, 200);
        }
      },

      searchUsers: function searchUsers() {
        this.set('filterCurrentStep', 0);
        this.set('filteredUsers', []);
        this.set('isLoading', true);
        _ember['default'].run.debounce(this, this.filterUsers, 200);
      },

      resetScrollSteps: function resetScrollSteps() {
        this.set('currentStep', 1);
      },

      deleteAccount: function deleteAccount(account) {
        var _this4 = this;

        if (account !== this.get('currentAccount.content')) {
          this.set('currentStep', 0);
          account.destroyRecord()['catch'](function (e) {
            if (e.errors[0] && e.errors[0].status === 404) {
              account.unloadRecord();
            }
          }).then(function () {
            if (_this4.get('model.account_custom_profiles.length') === 49) {
              _this4.updateScroll();
            }
          });
        }
      },

      newAccount: function newAccount() {
        this.transitionToRoute('settings.users.new');
      },

      sendInvitation: function sendInvitation() {
        var _this = this;
        this.get('model.invitation').save().then(function () {
          var inv = _this.store.createRecord('Account');
          _this.set('model.invitation', inv);
        });
      },

      openExportModal: function openExportModal() {
        this.notifyPropertyChange('exportName');
        this.set('exportModalIsOpened', true);
      },

      closeExportModal: function closeExportModal() {
        this.set('exportModalIsOpened', false);
      },

      closeProgressModal: function closeProgressModal() {
        this._closeProgressModal();
      },

      validateExport: function validateExport(exportConfig) {
        var _this5 = this;

        var stringContent = JSON.stringify({ filter: { 'email': this.get('searchField') }, sort: 'email' });
        var promise = this.get('store').createRecord('account-export-request', {
          export_type: exportConfig.export_format,
          file_file_name: exportConfig.file_name,
          content: stringContent,
          content_options: []
        }).save();

        this.set('export_promise', promise);
        promise.then(function () {
          _this5.send('closeExportModal');
          _this5.set('progressModalIsOpened', true);
        });
      },

      goToHistory: function goToHistory() {
        this.transitionToRoute('companies.exports.history');
        this._closeProgressModal();
      }
    }
  });
});