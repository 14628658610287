define("due-dashboard/templates/components/icons/ai-stars", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/icons/ai-stars.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el1 = dom.createElement("svg");
          dom.setAttribute(el1, "width", "20");
          dom.setAttribute(el1, "height", "20");
          dom.setAttribute(el1, "viewBox", "0 0 20 20");
          dom.setAttribute(el1, "fill", "none");
          dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M11.0385 3.69495C11.2561 3.14291 12.0368 3.14291 12.2543 3.69495L13.3519 6.47982C13.9746 8.05988 15.2244 9.31061 16.8034 9.93377L19.5863 11.0321C20.1379 11.2498 20.1379 12.0311 19.5863 12.2488L16.8034 13.3472C15.2244 13.9703 13.9746 15.221 13.3519 16.8011L12.2543 19.586C12.0368 20.138 11.2561 20.138 11.0385 19.586L9.94094 16.8011C9.31822 15.221 8.06838 13.9703 6.48945 13.3472L3.70656 12.2488C3.15492 12.0311 3.15492 11.2498 3.70656 11.0321L6.48945 9.93377C8.06838 9.31061 9.31822 8.05988 9.94094 6.47982L11.0385 3.69495Z");
          dom.setAttribute(el2, "fill", "url(#paint0_linear_604_26266)");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M3.18539 0.207013C3.29417 -0.069004 3.68453 -0.0690045 3.79331 0.207012L4.32337 1.55196C4.52264 2.05758 4.92259 2.45781 5.42785 2.65722L6.77184 3.18766C7.04766 3.29652 7.04766 3.68715 6.77184 3.79601L5.42785 4.32645C4.92259 4.52586 4.52264 4.9261 4.32337 5.43171L3.79331 6.77666C3.68453 7.05268 3.29417 7.05268 3.18539 6.77666L2.65533 5.43172C2.45606 4.9261 2.05611 4.52586 1.55085 4.32645L0.206866 3.79601C-0.0689548 3.68715 -0.0689554 3.29652 0.206865 3.18766L1.55085 2.65722C2.05611 2.45781 2.45606 2.05758 2.65533 1.55196L3.18539 0.207013Z");
          dom.setAttribute(el2, "fill", "url(#paint1_linear_604_26266)");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("defs");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("linearGradient");
          dom.setAttribute(el3, "id", "paint0_linear_604_26266");
          dom.setAttribute(el3, "x1", "-4.20932e-05");
          dom.setAttribute(el3, "y1", "10.1307");
          dom.setAttribute(el3, "x2", "19.3965");
          dom.setAttribute(el3, "y2", "10.1307");
          dom.setAttribute(el3, "gradientUnits", "userSpaceOnUse");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "stop-color", "#0AA8EB");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "offset", "0.64");
          dom.setAttribute(el4, "stop-color", "#9900F0");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "offset", "0.88");
          dom.setAttribute(el4, "stop-color", "#F900BF");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "offset", "1");
          dom.setAttribute(el4, "stop-color", "#FF2E7D");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("linearGradient");
          dom.setAttribute(el3, "id", "paint1_linear_604_26266");
          dom.setAttribute(el3, "x1", "-4.20932e-05");
          dom.setAttribute(el3, "y1", "10.1307");
          dom.setAttribute(el3, "x2", "19.3965");
          dom.setAttribute(el3, "y2", "10.1307");
          dom.setAttribute(el3, "gradientUnits", "userSpaceOnUse");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "stop-color", "#0AA8EB");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "offset", "0.64");
          dom.setAttribute(el4, "stop-color", "#9900F0");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "offset", "0.88");
          dom.setAttribute(el4, "stop-color", "#F900BF");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("stop");
          dom.setAttribute(el4, "offset", "1");
          dom.setAttribute(el4, "stop-color", "#FF2E7D");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 0
            },
            "end": {
              "line": 31,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/icons/ai-stars.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el1 = dom.createElement("svg");
          dom.setAttribute(el1, "width", "10");
          dom.setAttribute(el1, "height", "11");
          dom.setAttribute(el1, "viewBox", "0 0 10 11");
          dom.setAttribute(el1, "fill", "none");
          dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("linearGradient");
          dom.setAttribute(el2, "id", "ai-stars-gradient");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("stop");
          dom.setAttribute(el3, "class", "first-stop");
          dom.setAttribute(el3, "offset", "0%");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("stop");
          dom.setAttribute(el3, "class", "second-stop");
          dom.setAttribute(el3, "offset", "64%");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("stop");
          dom.setAttribute(el3, "class", "third-stop");
          dom.setAttribute(el3, "offset", "88%");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("stop");
          dom.setAttribute(el3, "class", "forth-stop");
          dom.setAttribute(el3, "offset", "100%");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M5.51925 2.34747C5.62803 2.07146 6.01838 2.07146 6.12717 2.34747L6.67594 3.73991C6.9873 4.52994 7.61222 5.1553 8.40169 5.46689L9.79313 6.01605C10.069 6.12491 10.069 6.51555 9.79313 6.62441L8.40169 7.17358C7.61222 7.48516 6.9873 8.11052 6.67594 8.90055L6.12717 10.293C6.01838 10.569 5.62803 10.569 5.51925 10.293L4.97047 8.90055C4.65911 8.11052 4.03419 7.48516 3.24472 7.17358L1.85328 6.62441C1.57746 6.51555 1.57746 6.12491 1.85328 6.01605L3.24472 5.46689C4.03419 5.1553 4.65911 4.52994 4.97047 3.73991L5.51925 2.34747Z");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M1.5927 0.603507C1.64709 0.465498 1.84226 0.465498 1.89665 0.603506L2.16168 1.27598C2.26132 1.52879 2.46129 1.7289 2.71392 1.82861L3.38592 2.09383C3.52383 2.14826 3.52383 2.34358 3.38592 2.39801L2.71392 2.66323C2.46129 2.76293 2.26132 2.96305 2.16169 3.21586L1.89665 3.88833C1.84226 4.02634 1.64709 4.02634 1.5927 3.88833L1.32766 3.21586C1.22803 2.96305 1.02806 2.76293 0.775427 2.66323L0.103433 2.39801C-0.0344774 2.34358 -0.0344777 2.14826 0.103433 2.09383L0.775426 1.82861C1.02805 1.7289 1.22803 1.52879 1.32766 1.27598L1.5927 0.603507Z");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element0, [5]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'fill');
          morphs[1] = dom.createAttrMorph(element2, 'fill');
          return morphs;
        },
        statements: [["attribute", "fill", ["get", "computedColor", ["loc", [null, [28, 541], [28, 554]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "fill", ["get", "computedColor", ["loc", [null, [29, 561], [29, 574]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/icons/ai-stars.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [1, 10], [1, 14]]], 0, 0, 0, 0], "trigger"], [], ["loc", [null, [1, 6], [1, 25]]], 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [31, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});