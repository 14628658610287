define('due-dashboard/models/respondent-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({

    rating_scale: belongsTo('RatingScale', { async: true }),
    ratings: hasMany('Rating', { async: true }),

    name: attr('string'),
    style: attr(),
    ctl_enabled: attr('boolean')
  });
});