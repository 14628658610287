define('due-dashboard/components/v2/libraries/surveys/navbar/design/elements/title-design', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    name: 'title',
    BUTTON_STATES: [{ value: 'default', name: '' }, { value: 'hovered', name: '_hovered' }, { value: 'focused', name: '_focused' }, { value: 'disabled', name: '_disabled' }],
    DEFAULT_STATE: { value: 'default' },

    currentDesignOptions: computed('isLoading', 'designOptions', function () {
      return this.get('designOptions');
    }),

    state: computed(function () {
      return this.BUTTON_STATES[0];
    }),

    styleName: computed('state', function () {
      return '' + this.name + this.get('state.name');
    }),

    actions: {
      setState: function setState(newState) {
        this.set('state', newState);
      }
    }
  });
});