define('due-dashboard/components/v2/libraries/surveys/navbar/-steps', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content'],

    STEP_KIND_COMPONENTS: ['content', 'hard_complete'],
    ELEMENTS_KIND_COMPONENTS: ['short_text_input', 'long_text_input', 'group', 'title', 'text', 'rating', 'single_select', 'multiple_select', 'prev_next_btns', 'redirect_btn', 'assign_segment', 'submit_step', 'soft_complete'],
    COMPONENT_OBJECT_MAPPING: {
      'stepDetail': 'v2/libraries/surveys/navbar/elements/elements-content',
      'elemDetail': 'v2/libraries/surveys/navbar/elements/element-content'
    },

    navigation: [],
    navigationIdx: [],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('navigation', []);
      this.set('navigationIdx', []);
    },

    computedNavigation: computed('survey_draft.survey_content.survey_steps_attributes', 'navigation', function () {
      var navigation = this.get('navigation');
      var steps = this.get('survey_draft.survey_content.survey_steps_attributes');
      var stepIdx = this.get('selectedStepIndex');
      if (!steps) {
        return [];
      }
      var refreshedNav = navigation.map(function (object, index) {
        var refreshedObject = null;

        if (index === 0) {
          refreshedObject = steps.find(function (elem) {
            return elem.uuid === object.uuid;
          });
        } else {
          refreshedObject = steps[stepIdx].survey_elements_attributes.find(function (elem) {
            return elem.uuid === object.uuid;
          });
        }
        return refreshedObject;
      });

      return refreshedNav;
    }),

    groupNavigationIdx: computed('navigationIdx', function () {
      var navigation = copy(this.get('navigationIdx'), true);

      if (navigation.length > 2) {
        navigation.shift();
        navigation.pop();
        return navigation;
      }

      return [];
    }),

    selectedStepIndex: computed('navigationIdx', function () {
      var navigation = this.get('navigationIdx');
      var stepIdx = navigation.length ? navigation[0] : 0;

      this.set('previewSelectedStepIndex', stepIdx);
      return stepIdx;
    }),

    selectedElementIndex: computed('navigationIdx', 'items', 'survey_draft.survey_content.survey_steps_attributes', function () {
      var navigation = this.get('navigationIdx');
      var elemIdx = navigation.length > 1 ? navigation[navigation.length - 1] : -1;

      this.set('previewSelectedElementIndex', this._getFlatElementIndex(elemIdx));
      this.notifyPropertyChange('previewSelectedElementIndex');
      return elemIdx;
    }),

    currentObjectParent: computed('computedNavigation', 'survey_draft', function () {
      var navigation = this.get('computedNavigation');
      var queueSize = navigation.length;

      return queueSize > 1 ? navigation[queueSize - 2] : null;
    }),

    currentObject: computed('computedNavigation', 'survey_draft', function () {
      var nav = this.get('computedNavigation');
      var lastObj = nav[nav.length - 1];

      return lastObj;
    }),

    currentObjectComponent: computed('currentObject', function () {
      var currentObj = this.get('currentObject');
      var nav = this.get('navigation');
      var stepKindMapping = this.STEP_KIND_COMPONENTS;
      var elemKindMapping = this.ELEMENTS_KIND_COMPONENTS;
      var componentMapping = this.COMPONENT_OBJECT_MAPPING;

      if (currentObj && elemKindMapping.includes(currentObj.kind) && nav.length > 1) {
        return componentMapping['elemDetail'];
      } else if (currentObj && stepKindMapping.includes(currentObj.kind)) {
        return componentMapping['stepDetail'];
      }
    }),

    _getFlatElementIndex: function _getFlatElementIndex(lastElemIdx) {
      var steps = this.get('survey_draft.survey_content.survey_steps_attributes');
      var stepIdx = this.get('previewSelectedStepIndex');
      var currentObject = this.get('currentObject');

      if (lastElemIdx > -1 && stepIdx > -1 && steps[stepIdx].survey_elements_attributes) {
        var flatIdx = steps[stepIdx].survey_elements_attributes.findIndex(function (elem) {
          return elem.uuid === currentObject.uuid;
        });
        return flatIdx;
      }

      return -1;
    },

    currentStep: computed('previewSelectedStepIndex', 'survey_draft.survey_content.survey_steps_attributes', function () {
      var stepIdx = this.get('previewSelectedStepIndex');
      var steps = this.get('survey_draft.survey_content.survey_steps_attributes');

      if (stepIdx === -1 || !steps) return null;
      return steps[stepIdx];
    }),

    currentElement: computed('previewSelectedElementIndex', 'survey_draft.survey_content.survey_steps_attributes', function () {
      var elemIdx = this.get('previewSelectedElementIndex');
      var stepIdx = this.get('previewSelectedStepIndex');
      var steps = this.get('survey_draft.survey_content.survey_steps_attributes');

      if (stepIdx === -1 || elemIdx === -1 || !steps || !steps[0]) return null;
      return steps[stepIdx].survey_elements_attributes[elemIdx];
    }),

    actions: {
      toggleState: function toggleState(selectedItem, idx) {
        var step = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

        var nav = this.get('navigation');
        var navIdx = this.get('navigationIdx');

        if (step) {
          nav = [];
          navIdx = [];
        }
        nav.push(selectedItem);
        navIdx.push(idx);

        this.set('navigation', nav);
        this.set('navigationIdx', navIdx);
        this.notifyPropertyChange('navigation');
        this.notifyPropertyChange('navigationIdx');
      },

      saveDraft: function saveDraft() {
        var dispatcher = this.get('actionsDispatcher');
        var steps = this.get('survey_draft.survey_content.survey_steps_attributes');
        var unsavedChanges = this.get('unsavedChanges');
        var saveCallback = this.get('group.autoSaveRevisionCallback');

        if (unsavedChanges && dispatcher && saveCallback) {
          dispatcher(saveCallback, steps);
          this.set('unsavedChanges', false);
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/libraries/navbar/-steps}} alert component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */