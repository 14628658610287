define("due-dashboard/templates/components/icons/-gesture", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/icons/-gesture.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "width", "17");
        dom.setAttribute(el1, "height", "16");
        dom.setAttribute(el1, "viewBox", "0 0 17 16");
        dom.setAttribute(el1, "fill", "none");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M12.9999 8.66371H13.9999C14.184 8.66371 14.3333 8.81303 14.3333 8.99722V9.66426C14.3333 9.84846 14.184 9.99778 13.9999 9.99778H12.9999V11.2251C13.0187 12.724 11.8308 13.96 10.3333 14C8.86049 14 7.66659 12.8054 7.66659 11.3319C7.70656 9.83343 8.94191 8.64494 10.4399 8.66371H11.6666V7.99667C11.6666 7.25988 11.0696 6.6626 10.3333 6.6626C9.35325 6.6626 8.78659 7.40301 8.07992 8.44358L8.07751 8.44714C7.37143 9.48685 6.57146 10.6648 4.99992 10.6648C3.52716 10.6648 2.33325 9.47025 2.33325 7.99667C2.33325 6.19145 3.84214 5.55757 4.96486 5.08591C4.97883 5.08004 4.99274 5.0742 5.00659 5.06838C5.98659 4.66149 6.45325 4.43469 6.45325 3.99445C6.45325 3.62605 6.15478 3.32741 5.78659 3.32741C5.29747 3.32725 4.82447 3.50236 4.45325 3.82102C3.94284 4.20992 3.3372 4.45415 2.69992 4.52808C2.6054 4.5332 2.51246 4.5022 2.43992 4.44136C2.37264 4.37679 2.33419 4.28783 2.33325 4.19456V3.52752C2.33539 3.36043 2.46078 3.22071 2.62659 3.20067C3.02111 3.1362 3.39148 2.96817 3.69992 2.71374C4.29641 2.24956 5.03093 1.99833 5.78659 2.00001C6.89115 2.00001 7.78659 2.89594 7.78659 4.00112C7.78659 5.3731 6.54256 5.88893 5.53262 6.30771L5.51325 6.31574C4.25992 6.82935 3.66659 7.16287 3.66659 7.99667C3.66659 8.73346 4.26354 9.33074 4.99992 9.33074C5.82659 9.33074 6.33325 8.73041 6.99992 7.6965C7.66659 6.6626 8.57325 5.32852 10.3333 5.32852C11.806 5.32852 12.9999 6.52309 12.9999 7.99667V8.66371ZM10.3333 12.6659C11.4266 12.6659 11.6666 11.512 11.6666 11.2251V9.99778H10.4399C9.67929 9.98221 9.04282 10.5719 8.99992 11.3319C8.99992 12.0686 9.59687 12.6659 10.3333 12.6659Z");
        dom.setAttribute(el2, "fill", "currentColor");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});