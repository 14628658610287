define("due-dashboard/templates/components/loading-skeleton/due-gradient-bar-skeleton", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/loading-skeleton/due-gradient-bar-skeleton.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "fill", "none");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("rect");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("defs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("linearGradient");
        dom.setAttribute(el3, "gradientUnits", "userSpaceOnUse");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "stop-color", "#0AA8EB");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "0.64");
        dom.setAttribute(el4, "stop-color", "#9900F0");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "0.88");
        dom.setAttribute(el4, "stop-color", "#F900BF");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("stop");
        dom.setAttribute(el4, "offset", "1");
        dom.setAttribute(el4, "stop-color", "#FF2E7D");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'viewBox');
        morphs[3] = dom.createAttrMorph(element1, 'width');
        morphs[4] = dom.createAttrMorph(element1, 'height');
        morphs[5] = dom.createAttrMorph(element1, 'rx');
        morphs[6] = dom.createAttrMorph(element1, 'fill');
        morphs[7] = dom.createAttrMorph(element1, 'fill-opacity');
        morphs[8] = dom.createAttrMorph(element2, 'id');
        return morphs;
      },
      statements: [["attribute", "width", ["get", "recWidth", ["loc", [null, [1, 13], [1, 21]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "height", ["get", "recHeight", ["loc", [null, [1, 33], [1, 42]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "viewBox", ["concat", ["0 0 ", ["get", "recWidth", ["loc", [null, [1, 60], [1, 68]]], 0, 0, 0, 0], " ", ["get", "recHeight", ["loc", [null, [1, 73], [1, 82]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "width", ["get", "recWidth", ["loc", [null, [2, 16], [2, 24]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "height", ["get", "recHeight", ["loc", [null, [2, 36], [2, 45]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "rx", ["get", "radius", ["loc", [null, [2, 53], [2, 59]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "fill", ["concat", [["get", "computedGradientUID", ["loc", [null, [2, 70], [2, 89]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "fill-opacity", ["get", "fillOpacity", ["loc", [null, [2, 108], [2, 119]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "gradientUID", ["loc", [null, [4, 25], [4, 36]]], 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});